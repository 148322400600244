import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Collapse, Modal, Checkbox } from "antd";
import { colors } from "../../assets/data/colors";
import { dummyTemplates } from "../../assets/data/templates";
import { LoadTemplatePage } from "../../models/entities/template";
import { PageResource } from "../../models/dtos/page_resource";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { createEmptyPage } from "../../services/utils/PageResourceUtils";
import { CollapseProps } from "antd/lib";
import CheckboxC from "../checkbox";

export function ModalLoadTemplates({ setSowModalTemplate, showModalTemplate }: {
    showModalTemplate: boolean,
    setSowModalTemplate: Dispatch<SetStateAction<boolean>>
}): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    // Track selected sub-option globally across all templates
    const [selectedOptions, setSelectedOptions] = useState<{
        settings: string | null,
        args: string | null,
        notifications: string | null,
        selection: string | null
    }>({
        settings: null,
        args: null,
        notifications: null,
        selection: null,
    });

    const [templatesPage, setTemplatePage] = useState<PageResource<LoadTemplatePage>>(createEmptyPage());
    const [collapseOptions, setCollapseOptions] = useState<CollapseProps['items']>([]);

    useEffect(() => {
        setTemplatePage(dummyTemplates);
    }, [dispatch]);

    const handleSubOptionChange = (option: keyof typeof selectedOptions, templateId: string) => {
        // Update the selectedOptions to only allow one option selected globally
        setSelectedOptions((prevState) => ({
            ...prevState,
            [option]: prevState[option] === templateId ? null : templateId, // Toggle the current template's option
        }));
    };

    const handleTemplateChange = (templateId: string, templateOptions: { settings?: any; args?: any; notifications?: any; selection?: any }) => {
        const allSelected = areAllOptionsSelected(templateId, templateOptions);

        // If all options are already selected, deselect all, otherwise select all
        if (allSelected) {
            setSelectedOptions((prevState) => ({
                ...prevState,
                settings: prevState.settings === templateId ? null : prevState.settings,
                args: prevState.args === templateId ? null : prevState.args,
                notifications: prevState.notifications === templateId ? null : prevState.notifications,
                selection: prevState.selection === templateId ? null : prevState.selection,
            }));
        } else {
            setSelectedOptions((prevState) => ({
                ...prevState,
                settings: templateOptions.settings ? templateId : prevState.settings,
                args: templateOptions.args ? templateId : prevState.args,
                notifications: templateOptions.notifications ? templateId : prevState.notifications,
                selection: templateOptions.selection ? templateId : prevState.selection,
            }));
        }
    };

    // Helper to determine if all sub-options are selected for a template
    const areAllOptionsSelected = (templateId: string, templateOptions: { settings?: any; args?: any; notifications?: any; selection?: any }) => {
        return (
            (templateOptions.settings ? selectedOptions.settings === templateId : true) &&
            (templateOptions.args ? selectedOptions.args === templateId : true) &&
            (templateOptions.notifications ? selectedOptions.notifications === templateId : true) &&
            (templateOptions.selection ? selectedOptions.selection === templateId : true)
        );
    };

    // Helper to determine if some but not all sub-options are selected (for indeterminate state)
    const areSomeOptionsSelected = (templateId: string, templateOptions: { settings?: any; args?: any; notifications?: any; selection?: any }) => {
        return (
            (templateOptions.settings && selectedOptions.settings === templateId) ||
            (templateOptions.args && selectedOptions.args === templateId) ||
            (templateOptions.notifications && selectedOptions.notifications === templateId) ||
            (templateOptions.selection && selectedOptions.selection === templateId)
        ) && !areAllOptionsSelected(templateId, templateOptions);
    };

    // Helper to get the selected sub-options for display in the label
    const getSelectedSubOptionsLabel = (templateId: string, templateOptions: { settings?: any; args?: any; notifications?: any; selection?: any }) => {
        const selectedOptionsList = [];

        if (templateOptions.settings && selectedOptions.settings === templateId) {
            selectedOptionsList.push("Settings");
        }
        if (templateOptions.args && selectedOptions.args === templateId) {
            selectedOptionsList.push("Args");
        }
        if (templateOptions.notifications && selectedOptions.notifications === templateId) {
            selectedOptionsList.push("Notifications");
        }
        if (templateOptions.selection && selectedOptions.selection === templateId) {
            selectedOptionsList.push("Selection");
        }

        return selectedOptionsList.length > 0 ? `(${selectedOptionsList.join(", ")})` : "";
    };

    useEffect(() => {
        const tempOptions = templatesPage.content.map((template) => {
            const nestedCollapseItems: CollapseProps['items'] = [];

            // Only render the options that are present in the template, and skip missing ones
            if (template.options.settings) {
                nestedCollapseItems.push({
                    key: 'settings',
                    label: (
                        <CheckboxC
                            label="Settings"
                            checked={selectedOptions.settings === template.id} // Only one setting selected at any time
                            onClick={(e: React.MouseEvent) => e.stopPropagation()} // Prevents collapse when clicking checkbox
                            onChange={() => handleSubOptionChange('settings', template.id)} // Handle sub-option change
                        />
                    ),
                    children: (
                        <>
                            {template.options.settings.title} <br />
                            {template.options.settings.githubBranch} <br />
                            {template.options.settings.browsers} <br />
                            {template.options.settings.total_runs} <br />
                            {template.options.settings.maxParallel} <br />
                        </>
                    )
                });
            }

            if (template.options.args) {
                nestedCollapseItems.push({
                    key: 'args',
                    label: (
                        <CheckboxC
                            label="Args"
                            checked={selectedOptions.args === template.id} // Only one args option selected at any time
                            onClick={(e: React.MouseEvent) => e.stopPropagation()} // Prevents collapse when clicking checkbox
                            onChange={() => handleSubOptionChange('args', template.id)} // Handle sub-option change
                        />
                    ),
                    children: (
                        <>
                            {template.options.args ? template.options.args.map((arg, index) => (
                                <div key={index}>{JSON.stringify(arg)}</div>
                            )) : 'No arguments available.'}
                        </>
                    )
                });
            }

            if (template.options.notifications) {
                nestedCollapseItems.push({
                    key: 'notifications',
                    label: (
                        <CheckboxC
                            label="Notifications"
                            checked={selectedOptions.notifications === template.id} // Only one notifications option selected
                            onClick={(e: React.MouseEvent) => e.stopPropagation()} // Prevents collapse when clicking checkbox
                            onChange={() => handleSubOptionChange('notifications', template.id)} // Handle sub-option change
                        />
                    ),
                    children: (
                        <>
                            {template.options.notifications.system?.map((sys) => (
                                <div key={sys.id}>{sys.title} - Status: {sys.status ? 'Enabled' : 'Disabled'}</div>
                            ))}
                            {template.options.notifications.slack?.map((slack, index) => (
                                <div key={index}>{JSON.stringify(slack)}</div>
                            ))}
                            {template.options.notifications.scripts?.map((script, index) => (
                                <div key={index}>{JSON.stringify(script)}</div>
                            ))}
                            {template.options.notifications.team?.map((team, index) => (
                                <div key={index}>{JSON.stringify(team)}</div>
                            ))}
                        </>
                    )
                });
            }

            if (template.options.selection) {
                nestedCollapseItems.push({
                    key: 'selection',
                    label: (
                        <CheckboxC
                            label="Selection"
                            checked={selectedOptions.selection === template.id} // Only one selection option selected
                            onClick={(e: React.MouseEvent) => e.stopPropagation()} // Prevents collapse when clicking checkbox
                            onChange={() => handleSubOptionChange('selection', template.id)} // Handle sub-option change
                        />
                    ),
                    children: (
                        <>
                            Cases: {template.options.selection?.cases.join(', ')} <br />
                            Sections: {template.options.selection?.sections.join(', ')} <br />
                            Components: {template.options.selection?.components.join(', ')} <br />
                        </>
                    )
                });
            }

            // Template checkbox: controls all sub-options
            const allSelected = areAllOptionsSelected(template.id, template.options);
            const someSelected = areSomeOptionsSelected(template.id, template.options);

            return {
                key: template.id,
                label: (
                    <Checkbox
                        checked={allSelected} // Fully checked if all sub-options are selected
                        indeterminate={someSelected} // Half-checked if some but not all sub-options are selected
                        onClick={(e: React.MouseEvent) => e.stopPropagation()} // Prevents collapse when clicking checkbox
                        onChange={() => handleTemplateChange(template.id, template.options)} // Select/Deselect all sub-options
                    >
                        <div style={{ textAlign: 'center' }}>
                            <span>{template.name} </span>
                            <span style={{ fontSize: 11, marginLeft:5 }}>{getSelectedSubOptionsLabel(template.id, template.options)}</span>
                        </div>
                    </Checkbox>
                ),
                children: <Collapse items={nestedCollapseItems} />
            };
        });

        setCollapseOptions(tempOptions);
    }, [templatesPage, selectedOptions]);

    const handleCancel = () => {
        setSowModalTemplate(false);
    };

    const handleOk = () => {
        setSowModalTemplate(false);
    };

    return (
        <Modal
            open={showModalTemplate}
            title="Load From Template"
            width={750}
            onOk={handleOk}
            onCancel={handleCancel}
            bodyStyle={{ height: 400, overflowY: 'auto' }}
            footer={[
                <Button key="Cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    style={{ background: colors.green.bamboo }}
                    onClick={handleOk}
                >
                    Load
                </Button>
            ]}
        >
            Show Templates
            <Collapse size="large" items={collapseOptions} />
        </Modal>
    );
}
