import {get, post, put, deletion} from '../../services/axiosWrapper';
import { PageResource } from "../../models/dtos/page_resource";
import { TeamSetting, TeamStats } from "../../models/entities/team";
import { Team } from "../../models/entities/team";
import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from "./creators/common_actions";
import { createEmptyPage } from "../../services/utils/PageResourceUtils";
import { AppDispatch} from "../store";
import { LandingProject, ProjectTeamOwners } from "../../models/entities/project";
import {MemberInfo, User} from "../../models/entities/user";
import { PermissionsInterface } from '../../models/entities/permissions';
import {addMemberParamsModalType} from "../../models/entities/helpers/modal";

const api_path = "/api/team/"

interface org_team {
    team_id: number,
    org_id: number,
    search?: string
}

interface invite_user_team {
    org_id: number,
    team_id: number,
    email: string,
    sender_id: number
}

interface putSettingActionsParams extends org_team{
    name?: string,
    description?: string,
    visibility?: boolean,
    team_type_id?: number
}

interface updateTeamOwnerParams extends org_team{
    owner_id?: number
}


export const getTeamStats = (org_id:number, user_id?:number, search?: string) => (dispatch: AppDispatch): Promise<PageResource<TeamStats>> => {
    dispatch(fetchStartAction());

    let page: PageResource<TeamStats> = createEmptyPage()
    const request = get(`${api_path}getTeamStats?org_id=${org_id}&user_id=${user_id}&search=${search}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            page = value.data;
            return page;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getOrgTeams = (params: {
    org_id: number,
    user_id: number,
    search?: string,
    limit: number,
    team_id?: number| string,
    teams_type: 'my_teams' | 'public' | 'invited' | 'all'}) => (dispatch: AppDispatch): Promise<PageResource<Team>> => {
    dispatch(fetchStartAction());

    const request = get(`${api_path}getOrgTeams`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getTeamInvites  = (user_id: number, org_id:number, search?: string) => (dispatch: AppDispatch): Promise<PageResource<TeamStats>> => {
    dispatch(fetchStartAction());

    let page: PageResource<TeamStats> = createEmptyPage()
    const request = get(`${api_path}getTeamInvites?user_id=${user_id}&org_id=${org_id}&search=${search}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            page.content = value.data;
            return page;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const deleteTeam = (team_id:number) => (dispatch: AppDispatch): Promise<number> => {
    dispatch(fetchStartAction());

    const request: any = deletion(`${api_path}deleteTeam?team_id=${team_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.status;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const createTeam = (data:{ name:string, description:string, org_id: number}, teams:Team[]) => (dispatch: AppDispatch): Promise<Team[]> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}create`, data)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                value.data = [...teams, value.data.new_team]
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject(reason.response.data); //
            })

}

export const updateTeam = (params: putSettingActionsParams) => (dispatch: AppDispatch): Promise<PageResource<LandingProject>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateTeam`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateTeamNotificationSetting = (params: {team_id: number, org_id: number, wall_posts?: boolean, new_member?: boolean, new_join?: boolean}) => (dispatch: AppDispatch): Promise<boolean> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}updateTeamNotificationSetting`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getTeamSettings = (params: {org_id:number, team_id:number}) => (dispatch: AppDispatch): Promise<{content: TeamSetting}> => {
    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getTeamSettings`, params)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}

export const updateTeamOwner = (params: updateTeamOwnerParams) => (dispatch: AppDispatch): Promise<PageResource<ProjectTeamOwners>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateTeamOwner`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getTeamUsers = (params: org_team) => (dispatch: AppDispatch): Promise<PageResource<User>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getTeamUsers`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteTeamMember = (team_id: number, member_ids: number[]) => (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());
    
    const request: any = deletion(`${api_path}deleteTeamMember?`, { team_id,member_ids:member_ids })
    return Promise.resolve(request)
    .then(value => {
        dispatch(fetchSuccessAction());

        return value;
    })
    .catch(reason => {
        dispatch(fetchErrorAction(reason));
        return Promise.reject();
    })
}

export const addTeamMembers = (params: addMemberParamsModalType) => (dispatch: AppDispatch): Promise<PageResource<MemberInfo>> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}addTeamMembers`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getParentTeams  = (params: { team_id:number }) => (dispatch: AppDispatch): Promise<number[]> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getParentTeams`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getTeamMembers = (params: {
    id:number,
    search:string,
    limit:number,
    offset:number
}) => (dispatch: AppDispatch): Promise<PageResource<MemberInfo>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getTeamMembers`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateUserTeamRole = (params: {id: number, role_id: number}) => (dispatch: AppDispatch): Promise<{"new_role": number, "new_label": string, permissions: PermissionsInterface}> =>{
    dispatch(fetchStartAction());
    
    const request: any = put(`${api_path}updateUserTeamRole`, params)    
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const sendTeamInviteToUser = (params: invite_user_team) => (dispatch: AppDispatch):Promise<boolean> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}sendTeamInviteToUser`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export type memberType = "user" | "team"

export const getMembersNotInTeam = (params: {
    id: number,
    org_id: number,
    search: string,
    searchFor: memberType[]
}) => (dispatch: AppDispatch): Promise<{
    id: number,
    member_type: 1 | 2,
    email: string,
    name: string
}[]> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getMembersNotInTeam`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateWallPostStatus = (data:{org_id:number, team_id:number})=> (dispatch: AppDispatch): Promise<number[]> => {
    dispatch(fetchStartAction());
    const request: any = post(`${api_path}updateWallPostStatus`, data)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data.content;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const acceptTeamInvite = (data:{org_id:number, team_id:number, user_id: number})=> (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());
    const request: any = post(`${api_path}acceptTeamInvite`, data)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data.content;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const declineTeamInvite  = (team_id:number, user_id: number)=> (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());
    const request: any = deletion(`${api_path}declineTeamInvite?team_id=${team_id}&user_id=${user_id}`)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data.content;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const joinTeam  = (params: {org_id: number, team_id:number, user_id: number})=> (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());
    const request: any = post(`${api_path}joinTeam`, {params})
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data.content;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

// export const leaveTeam  = (org_id: number, team_id:number, user_id: number)=> (dispatch: AppDispatch): Promise<boolean> => {
//     dispatch(fetchStartAction());
//     const request: any = deletion(`${api_path}leaveTeam?org_id=${org_id}&team_id=${team_id}&user_id=${user_id}`)
//     return Promise.resolve(request)
//             .then(value => {
//                 dispatch(fetchSuccessAction());
//                 return value.data.content;
//             })
//             .catch(reason => {
//                 dispatch(fetchErrorAction(reason));
//                 return Promise.reject();
//             })
// }
