import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Space } from 'antd';
import ModalC from "../modal";
import RichEditor from "../rich_editor/rich_editor";
import { createWallPost, updatePost } from "../../redux/actions/team_wall_actions";
import { getTeamUsers } from "../../redux/actions/team_actions";
import { useAppContext } from "../../AppContext";
import { AppDispatch } from "../../redux/store";
import { WallPosts, NewWallPost } from "../../models/entities/team";
import { empty_post } from "../../services/EmptyEntities/EmptyPost";
import './post_popup.css';
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";

interface PostProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    wallPostPage: WallPosts[];
    setWallPostPage: React.Dispatch<React.SetStateAction<WallPosts[]>>;
    wallPinnedPostPage: WallPosts[];
    setWallPinnedPostPage: React.Dispatch<React.SetStateAction<WallPosts[]>>;
    selectedPost: WallPosts;
    setSelectedPost: React.Dispatch<React.SetStateAction<WallPosts>>;
    readFlag?: boolean;
    setReadFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CreatePostPopup(props: PostProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const { currentOrg, currentTeam, loggedInUser} = useAppContext();
    const { visible, setVisible, wallPostPage, setWallPostPage, selectedPost, wallPinnedPostPage, setWallPinnedPostPage, setSelectedPost, readFlag, setReadFlag } = props;
    const [post, setPost] = useState<string>("");
    const [tagOptions, setTagOptions] = useState<{ id: number; value: string }[]>([]);

    useEffect(() => {
        dispatch(getTeamUsers({ org_id: currentOrg.id, team_id: currentTeam.id })).then(users => {
            const options = users.content.map(user => ({ id: user.id, value: `${user.name} ${user.lastname}` }));
            setTagOptions(options);
        }).catch((err) => {
            safeHandleErrorResponse(err)
        });
    }, [dispatch, currentOrg.id, currentTeam.id]);

    const handleEditorChange = (content: string) => {
        setPost(content);
    };

    const handleCancel = () => {
        setVisible(false);
        setSelectedPost(empty_post);
        setPost('');
    };

    const handlePost = async (isCreating: boolean) => {
        const postPayload: NewWallPost = {
            org_id: currentOrg.id,
            team_id: currentTeam.id,
            user_id: loggedInUser.id,
            post_text: post,
            post_image: post.includes('<img') ? post : null,
            ...(!isCreating && {
                post_id: selectedPost.post_id,
                poster: selectedPost.poster,
                timestamp: selectedPost.timestamp,
                pinned: selectedPost.pinned
            })
        };

        if (post.replace(/<(?!img)[^>]+>/g, '').trim().length > 0) {
            if (!isCreating && selectedPost.pinned === 1) {
                await dispatch(updatePost(postPayload as WallPosts, wallPinnedPostPage));
                setWallPinnedPostPage(wallPinnedPostPage);
            }

            const action = isCreating ?
                createWallPost(postPayload, wallPostPage)
                : updatePost(postPayload as WallPosts, wallPostPage);
            // const updatedPosts = await dispatch(action);
            dispatch(action)
                .then(updatedPosts=>{
                    setWallPostPage(updatedPosts);
                    setVisible(false);
                    setPost('');
                    setSelectedPost(empty_post);
                    setReadFlag && setReadFlag(!readFlag);

                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }
    };

    return (
        <ModalC open={visible} title={selectedPost === empty_post ? 'Create Post' : 'Edit Post'} onOk={() => handlePost(selectedPost === empty_post)} onCancel={handleCancel}>
            <Form.Item>
                <Space>
                    <RichEditor
                        value={selectedPost === empty_post ? post : selectedPost.post_text}
                        onChange={handleEditorChange}
                        atValues={tagOptions}/>
                </Space>
            </Form.Item>
        </ModalC>
    );
}