import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./post_popup.css";
import React from "react";
import ModalC from "../modal";
import { Form, Input, Switch } from 'antd';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import 'quill-image-upload';
import { useAppContext } from "../../AppContext";
import { AppDispatch } from "../../redux/store";
import ButtonC from "../button";
import { empty_argument } from "../../services/EmptyEntities/EmptyArgs";
import { ArgumentScriptSetting } from "../../models/entities/settings";
import { saveProjectSystemArgument } from "../../redux/actions/project_actions";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";

type SystemArgumentProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  sysArgs?: ArgumentScriptSetting[];
  setRunSystArgs?: Dispatch<ArgumentScriptSetting[]>;
  selectedArg?: ArgumentScriptSetting;
  setSelectedArg?: Dispatch<SetStateAction<ArgumentScriptSetting | undefined>>;
}

export default function SystemArgument(props: SystemArgumentProps):JSX.Element {
  
  const dispatch: AppDispatch = useDispatch();
  const {currentOrg, currentProject, currentProjectSettings, setAppContextProjectSettings} = useAppContext();

  const org_id = currentOrg.id
  const project_id = currentProject.id
  const {visible, setVisible, sysArgs, setRunSystArgs, selectedArg, setSelectedArg} = props;

  const [argTitle, setArgTitle] = useState<string>('');
  const [argLabel, setArgLabel] = useState<string>('');
  const [argDefaultValue, setArgDefaultValue] = useState<string>('');
  const [argStatus, setArgStatus] = useState<boolean>(false);
  const [restriction, setRestriction] = useState<boolean>(false);

  useEffect(() => {
    setArgTitle(selectedArg?.title || '');
    setArgLabel(selectedArg?.label || '');
    setArgDefaultValue(selectedArg?.default_value || '');
    setArgStatus(selectedArg?.status || false);
  }, [selectedArg]);

  const handleSystArgRunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRestriction(false)
    const inputValue = event.target.value;

    // Validate against .env restrictions
    const envValueRegex = /^[A-Za-z_][A-Za-z0-9_]*$/;
    if (!envValueRegex.test(inputValue)) {
      // If the input value violates the .env restrictions, don't update the state
      setRestriction(true)
    }

    // If the input value passes validation, update the state
    setArgTitle(inputValue);
    setArgLabel(inputValue.replace(/ /g, "_"))
  };

  const handleSystArgDefaultRunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setArgDefaultValue(event.target.value)
  };

  const handleRunSystArgSwitchChange = (event: boolean) => {
    setArgStatus(event)
 };

  const handleCancel = () => {
    setVisible(false)
    setArgTitle('')
    setArgLabel('')
    setArgDefaultValue('')
    setArgStatus(false)
    if (selectedArg && setSelectedArg)
    {setSelectedArg(empty_argument)}
};

const handleUpdateDefaultProjectSystArgs = async () => {
    const params = {
        project_id: project_id,
        org_id: org_id,
        title: argTitle,
        label: argLabel,
        default_value: argDefaultValue,
        status: argStatus}
    
    const update_params = {
        project_id: project_id,
        org_id: org_id,
        id: selectedArg?.id,
        title: argTitle,
        label: argLabel,
        default_value: argDefaultValue,
        status: argStatus}

    dispatch(saveProjectSystemArgument(selectedArg === empty_argument ? params : update_params)).then(() => {
        if (selectedArg === empty_argument || selectedArg === undefined) {
            if (setRunSystArgs)
            {const updated_args: any = sysArgs?.push(params); setRunSystArgs(updated_args)}
        }
        if (selectedArg !== empty_argument || selectedArg !== undefined) {
            setRunSystArgs && setRunSystArgs(sysArgs ? sysArgs.map(arg => arg.id === selectedArg?.id ? update_params : arg) : []);
            const updatedArgsSystem = currentProjectSettings.args.system.map(arg =>
                arg.title === selectedArg?.title ? { ...arg, title: update_params.title } : arg
              );
              
              const updatedProjectSettings: any = {
                ...currentProjectSettings,
                args: {
                  ...currentProjectSettings.args,
                  system: updatedArgsSystem
                }
              };
              
              setAppContextProjectSettings(updatedProjectSettings);
        }

        if (selectedArg === empty_argument) {
            let newTitle = { title: argTitle };

            // Ensure that currentProjectSettings is of type ProjectRunSettings
            let updatedSettings = {
            ...currentProjectSettings, // Copy existing properties
            args: {
                ...currentProjectSettings.args, // Copy existing args
                system: [...currentProjectSettings.args.system, newTitle], // Update the system array with the new title
            },
            };
            setAppContextProjectSettings(updatedSettings);
        }
    }).catch((err) => {
      safeHandleErrorResponse(err)
  })

    setVisible(false)
    setArgTitle('')
    setArgLabel('')
    setArgDefaultValue('')
    setArgStatus(false)
    if (selectedArg && setSelectedArg)
    {setSelectedArg(empty_argument)}
};

const footer_buttons = [
  <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>,
  <ButtonC key="submit_ok" type="primary" onClick={handleUpdateDefaultProjectSystArgs} text={selectedArg === empty_argument ? 'Create' : 'Save'} 
           disabled={argTitle.length < 4 || (sysArgs?.some(item => item.title === argTitle) && selectedArg?.title !== argTitle) || restriction}
           />
];


  return (
    <ModalC open={visible} title={selectedArg !== empty_argument ? 'Edit System Argument' : 'Create System Argument'} onOk={handleUpdateDefaultProjectSystArgs} onCancel={handleCancel}
    footer={footer_buttons}>
      <Form layout="vertical">
        <Form.Item label="Title">
        {sysArgs?.some(item => item.title === argTitle) && selectedArg?.title !== argTitle && <p style={{color: '#F6C324'}}>The specific System Argument already exists!</p>}
        {argTitle.length > 0 && restriction && <p style={{color: '#F6C324'}}>Invalid input value. Please use only letters, numbers, and underscores, starting with a letter or underscore!</p>}
          <Input
            placeholder='Provide value'
            onChange={handleSystArgRunChange}
            value={argTitle} />
        </Form.Item>
        <Form.Item label="Label">
          <Input
            placeholder='Autocomplete'
            disabled={true}
            value={argLabel} />
        </Form.Item>
        <Form.Item label="Default Value">
          <Input
            placeholder='Provide default value'
            onChange={handleSystArgDefaultRunChange}
            value={argDefaultValue} />
        </Form.Item>
        <Form.Item label="Status">
          <Switch
            onChange={handleRunSystArgSwitchChange}
            checked={argStatus}
            defaultChecked={argStatus} />
        </Form.Item>
      </Form>
    </ModalC>
  );
}
