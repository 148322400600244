import React, {Suspense, useEffect, useRef, useState} from 'react';
import { useParams, Outlet } from 'react-router-dom';
import Sidemenu from './sidemenu';
import HeaderMenu from './header';
import {Layout} from 'antd';
import './index.css';
import {useAppContext} from "../../AppContext";
import {hasOnlyNumbers} from "../../assets/helpers/helper";
import {getProjects} from "../../redux/actions/project_actions";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {getMyOrgs} from "../../redux/actions/user_actions";

import {
    orgMenuItems,
    projectMenuItems,
    sideMenuItems,
    teamMenuItems,
    userMenuItems
} from "./menu_items/sidebar_menu_items";
import Spin from "antd/lib/spin";
import {LoadingOutlined} from "@ant-design/icons";
import {Project} from "../../models/entities/project";
import { Organization } from '../../models/entities/organization';
import {empty_organization} from "../../services/EmptyEntities/EmptyOrganization";
import SkeletonLayout from "../../components/skeleton";
import {Team} from "../../models/entities/team";
import {getOrgTeams} from "../../redux/actions/team_actions";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import { getLoggedUserPermissions } from '../../redux/actions/permission_actions';


function Index(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const navigate = useNavigate();

    const {
        loggedInUser,
        setAppContextOrg,
        setAppContextTeam,
        setAppContextProject,
        setUserPerms,
        userPerms
    } = useAppContext();

    const {org_domain, project_key, team_id, user_id, team_name}  = useParams<{
        org_domain?: string,
        project_key?: string,
        team_id?: string,
        team_name?: string,
        user_id?: string
    }>()

    // let new_menu_type:SideMenuOptions = 'org';
    let root = `/${org_domain}/`;
    let menuTitle: "Organization" | "Project" | "Team" | "User" = 'Organization'
    if(org_domain && project_key){
        menuTitle = 'Project'
        root = `${root}projects/${project_key}/`
    }else if(org_domain && team_id){
        root = `${root}teams/${team_id}/${team_name}/`
        menuTitle = 'Team'
    }else if(org_domain && user_id){
        root = `${root}user/${user_id}/`
        menuTitle = 'User'
    }else if(org_domain && !user_id && !team_id && !project_key){
        menuTitle = 'Organization'
    }

    // refs
    const previousOrgDomainRef = useRef<string | undefined>(undefined);
    const previousTeamIdRef = useRef<string | undefined>(undefined);
    const previousPKeyRef = useRef<string | undefined>(undefined);


    // state
    const [selectedSideMenu, setSelectedSideMenu] = useState<any>(['0'])
    const [org, setOrg] = useState<Organization>(empty_organization)
    const [projects, setProjects] = useState<Project[]>([])
    const [teams, setTeams] = useState<Team[]>([])
    const [menuItems, setMenuItems] = useState<sideMenuItems[]>([])

    const [useSkeleton, setUseSkeleton] = useState<boolean>(false)

    const initOrg = (org:Organization) => {
        setOrg(org)
        setAppContextOrg(org)
        previousOrgDomainRef.current = org.domain
        if(!team_id && !project_key){
            setUseSkeleton(false)
        }
    }

    useEffect(() => {
        if (org !== empty_organization) {
            dispatch(getLoggedUserPermissions(org.id)).then(found => {
                setUserPerms(found)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
    }, [dispatch, org])

    useEffect(()=>{
        let default_menu_items = orgMenuItems(userPerms)
        if(org_domain && project_key){
            default_menu_items = projectMenuItems(userPerms)
        }else if(org_domain && team_id){
            default_menu_items = teamMenuItems(userPerms)
        }else if(org_domain && user_id){
            default_menu_items = userMenuItems(userPerms)
        }else if(org_domain && !user_id && !team_id && !project_key){
            default_menu_items = orgMenuItems(userPerms)
        }
        setMenuItems(default_menu_items)
    }, [userPerms, org_domain, user_id, team_id, project_key])

    const initProject = (project:Project) => {
        setAppContextProject(project)
        previousPKeyRef.current = project.p_key;
        setUseSkeleton(false)
    }

    const initTeam = (team:Team) => {
        setAppContextTeam(team)
        previousTeamIdRef.current = team.id.toString();
        setUseSkeleton(false)
    }

    const set_teams = (teamsN:Team[], org_id:number) => {
        if(team_id){
            const foundTeam = teamsN.find(team => {
                return team.id.toString() === team_id
            });
            if (foundTeam) {
                initTeam(foundTeam)
            } else {
                dispatch(getOrgTeams({
                    org_id,
                    user_id: loggedInUser.id,
                    limit: 1,
                    team_id,
                    teams_type: "my_teams"
                }))
                    .then(found_teams => {
                        if(found_teams.content.length > 0){
                            initTeam(found_teams.content[0])
                        }else{
                            navigate('/errors/403')
                        }
                    }).catch((err) => {
                        safeHandleErrorResponse(err)
                    })
            }
        }
    }

    const set_project = (projectsN:Project[], org_id:number) => {
        if(project_key){
            const foundProject = projectsN.find(project => project.p_key === project_key);
            if (foundProject) {
                initProject(foundProject)
            } else {
                dispatch(getProjects({
                    org_id,
                    user_id: loggedInUser.id,
                    key: project_key
                }))
                    // key:changedProject?currentProject.p_key:project_key}))
                    .then(found_projects => {
                        if(found_projects.content.length > 0){
                            initProject(found_projects.content[0])
                        }else{
                            navigate('/errors/403')
                        }
                    }).catch((err) => {
                        safeHandleErrorResponse(err)
                    })
            }
        }
    }

    useEffect(() => {
        const changedOrg:boolean = previousOrgDomainRef.current !== org_domain
        const changedProject:boolean = (project_key && previousPKeyRef.current !== project_key) as boolean
        const changedTeam:boolean = (team_id && hasOnlyNumbers(team_id) && previousTeamIdRef.current !== team_id) as boolean

        if(changedProject || changedTeam || changedOrg){
            setUseSkeleton(true)
            if (changedOrg){
                dispatch(getMyOrgs({user_id: loggedInUser.id, domain:org_domain}))
                    .then(found_orgs => {
                        if(found_orgs.content.length > 0){
                            const org = found_orgs.content[0]
                            initOrg(org)

                            dispatch(getProjects({
                                org_id: org.id,
                                user_id: loggedInUser.id,
                                limit: 5
                            }))
                                .then((found_projects)=>{
                                    setProjects(found_projects.content)
                                    set_project(found_projects.content, org.id)
                                }).catch((err) => {
                                    safeHandleErrorResponse(err)
                                })

                            dispatch(getOrgTeams({
                                org_id: org.id,
                                user_id: loggedInUser.id,
                                limit:5,
                                teams_type: 'my_teams'
                            }))
                                .then(found_teams=>{
                                    setTeams(found_teams.content)
                                    set_teams(found_teams.content, org.id)
                                }).catch((err) => {
                                    safeHandleErrorResponse(err)
                                })

                        }else{
                            navigate('/errors/403')
                        }
                    })

            }else if(changedProject){
                set_project(projects, org.id)
            }else if(changedTeam){
                set_teams(teams, org.id)
            }
        }
    }, [org_domain, project_key, team_id]);


    if(useSkeleton){
        return <SkeletonLayout/>
    }

    return <Layout style={{minHeight: "100vh", minWidth: 1100}}>
        <Sidemenu root={root}
                  menuTitle={menuTitle}
                  menu_items={menuItems}
                  selectedSideMenu={selectedSideMenu}
                  setSelectedSideMenu={setSelectedSideMenu}/>
        <Layout className="site-layout">
            <HeaderMenu org={org}
                        projects={projects}
                        teams={teams}
                setSelectedSideMenu={setSelectedSideMenu}/>
            <Layout.Content className="site-layout-background site-layout-content">
                <Suspense fallback={<Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}>
                    <Outlet />{/* <RouterProvider router={getRoutes} /> */}
                </Suspense>
            </Layout.Content>
        </Layout>
    </Layout>

}

export default Index;