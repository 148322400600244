import React, {useEffect, useState} from 'react';
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
import { Button, FloatButton, Select, Space, Table, Tag } from 'antd';
import { ProvidePermissionsInterface } from '../../models/entities/permissions';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { getOrgUserInfo } from '../../redux/actions/user_actions';
import { PageResource } from '../../models/dtos/page_resource';
import { OrgUsersInfo } from '../../models/entities/user';
import { createEmptyPage } from '../../services/utils/PageResourceUtils';
import { useAppContext } from '../../AppContext';
import { getJobs } from '../../redux/actions/job_actions';
import { Job } from '../../models/entities/job';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import { addUserPermission, getOrgMembersPermissions, removeUserPermission } from '../../redux/actions/permission_actions';
import Search from 'antd/es/input/Search';

const {Option} = Select;


function Permissions(): JSX.Element {

    const dispatch: AppDispatch = useDispatch();

    const {currentOrg} = useAppContext();

    const [userPage, setUsersPage] = useState<PageResource<OrgUsersInfo>>(createEmptyPage());
    const [jobPage, setJobPage] = useState<PageResource<Job>>(createEmptyPage());
    const [selectedUsersMap, setSelectedUsersMap] = useState<{ [key: string]: number[] }>({});
    const [selectedJobsMap, setSelectedJobsMap] = useState<{ [key: string]: number[] }>({});
    const [permissions, setPermissions] = useState<ProvidePermissionsInterface[]>([]);
    const [search, setSearch] = useState<string>('')

    const columns = () => {
        let columns: object[] = 
        [{
            title: "Permission",
            dataIndex: "permission",
            key: "permission",
            ellipsis: true
        },{
            title: "Users",
            dataIndex: "users",
            key: "users",
            ellipsis: true
        },{
            title: "Jobs",
            dataIndex: "jobs",
            key: "jobs",
            ellipsis: true
        }]


        return columns
    }

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getOrgUserInfo(currentOrg.id, '')).then(foundPage => {
                setUsersPage(foundPage)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch, currentOrg])

    useEffect(() => {
        const fetchData = async () => {
                dispatch(getJobs(currentOrg.id)).then(foundPage => {
                    setJobPage(foundPage)
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })

        }
        fetchData();
    }, [dispatch])

    useEffect(() => {
        const fetchData = async () => {
                dispatch(getOrgMembersPermissions(currentOrg.id, search)).then(foundPage => {
                    setPermissions(foundPage.content)
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })

        }
        fetchData();
    }, [dispatch, currentOrg])

    const handleUsersChange = (permissionLabel: string, selectedIds: number[]) => {
        setSelectedUsersMap(prev => ({
            ...prev,
            [permissionLabel]: selectedIds // Update selected users for this permission
        }));
    };

    const handleJobsChange = (permissionLabel: string, selectedIds: number[]) => {
        setSelectedJobsMap(prev => ({
            ...prev,
            [permissionLabel]: selectedIds // Update selected jobs for this permission
        }));
    };

    const handleAddUsers = (permission: ProvidePermissionsInterface) => {
        const selectedUsersIds = selectedUsersMap[permission.label] || [];
        if (selectedUsersIds.length === 0) return;
    
        dispatch(addUserPermission({
            org_id: currentOrg.id, 
            entity_ids: selectedUsersIds, 
            entity_type: 1, 
            permission: permission.label
        }))
        .then(() => {

            // Optimistically update the users for this permission
            const updatedUsers = [
                ...permission.users,
                ...selectedUsersIds
                    .map(id => {
                        const user = userPage.content.find(u => u.user_info.id === id);
                        return user ? { id: user.user_info.id, fullname: `${user.user_info.name} ${user.user_info.lastname}` } : undefined;
                    })
                    .filter((user): user is { id: number; fullname: string } => user !== undefined) // Filter out undefined
            ];
    
            permission.users = updatedUsers; // Safely update with filtered users
    
            // Clear selected users for this permission
            setSelectedUsersMap(prev => ({ ...prev, [permission.label]: [] }));
        })
        .catch(err => {
            safeHandleErrorResponse(err);
        });
    };

    const handleAddJobs = (permission: ProvidePermissionsInterface) => {
        const selectedJobsIds = selectedJobsMap[permission.label] || [];
        if (selectedJobsIds.length === 0) return;
    
        dispatch(addUserPermission({
            org_id: currentOrg.id, 
            entity_ids: selectedJobsIds, 
            entity_type: 2, 
            permission: permission.label
        }))
        .then(() => {

            // Optimistically update the jobs for this permission
            const updatedJobs = [
                ...permission.jobs,
                ...selectedJobsIds
                    .map(id => {
                        const job = jobPage.content.find(j => j.id === id);
                        return job ? { id: job.id, fullname: `${job.name}` } : undefined;
                    })
                    .filter((job): job is { id: number; fullname: string } => job !== undefined) // Filter out undefined
            ];
    
            permission.jobs = updatedJobs; // Safely update with filtered jobs
    
            // Clear selected jobs for this permission
            setSelectedJobsMap(prev => ({ ...prev, [permission.label]: [] }));
        })
        .catch(err => {
            safeHandleErrorResponse(err);
        });
    };

    const handleRemoveUser = (id: number, permission: ProvidePermissionsInterface) => {
        dispatch(removeUserPermission({
            org_id: currentOrg.id, 
            entity_id: id, 
            entity_type: 1, 
            permission: permission.label
        }))
        .then(found => {
            console.log(found);
    
            // Optimistically remove the user from the permission's user list in an immutable way
            const updatedUsers = permission.users.filter(user => user.id !== id);
    
            // Update the permission.users array safely
            permission.users = [...updatedUsers]; // Ensure you create a new array reference
    
            setPermissions(prevPermissions => prevPermissions.map(p => 
              p.label === permission.label ? { ...permission, users: updatedUsers } : p
            ));
            
        })
        .catch(err => {
            safeHandleErrorResponse(err);
        });
    };
    

    const handleRemoveJob = (id: number, permission: ProvidePermissionsInterface) => {
        dispatch(removeUserPermission({
            org_id: currentOrg.id, 
            entity_id: id, 
            entity_type: 2, 
            permission: permission.label
        }))
        .then(() => {

            // Optimistically remove the user from the permission's user list
            const updatedJobs = permission.jobs.filter(job => job.id !== id);
            permission.jobs = [...updatedJobs];

            setPermissions(prevPermissions => prevPermissions.map(p => 
                p.label === permission.label ? { ...permission, jobs: updatedJobs } : p
              ));

        })
        .catch(err => {
            safeHandleErrorResponse(err);
        });
    };

    const data = () => {
        let data: any = [];
        if(permissions){
            permissions.forEach((permission)=> {
                data.push({
                    key: `${permission.title}`,
                    permission: permission.title,
                    users: <>
                             {permission.users.map((user) => (
                             <React.Fragment key={user.id}>
                             <Tag
                                 style={{
                                 border: 'none',         // Removes the border
                                 backgroundColor: 'transparent',  // Makes the background transparent
                                 boxShadow: 'none',      // Removes any box shadow
                                 color: '#000'           // Optionally set the text color
                                 }}
                                 closable
                                 onClose={() => handleRemoveUser(user.id, permission)}
                             >
                                 {user.fullname}
                             </Tag>
                             <br />
                             </React.Fragment>))}
                             <Select
                                key='add-users_perms'
                                mode="multiple"
                                placeholder="Add Users"
                                value={selectedUsersMap[permission.label]}
                                onChange={(selectedIds) => handleUsersChange(permission.label, selectedIds)}
                                optionLabelProp="label"
                                style={{width: 200, marginTop: 10}}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
                            >
                                {
                                    userPage.content.map(user=>{
                                        return <Option key={user.user_info.id} 
                                                       value={user.user_info.id} 
                                                       label={user.user_info.name + " " + user.user_info.lastname}
                                                       disabled={permission.users.some(pUser => pUser.id === user.user_info.id)}>
                                            <Space>{user.user_info.name + " " + user.user_info.lastname}</Space>
                                        </Option>
                                    })
                                }
                            </Select><br/>
                            <Button type="primary" onClick={() => handleAddUsers(permission)} style={{marginTop: 10}} disabled={(selectedUsersMap[permission.label] || []).length === 0}>
                                    Add
                            </Button>
                            </>,
                    jobs: <>
                          {permission.jobs.map((job) => (
                             <React.Fragment key={job.id}>
                             <Tag
                                 style={{
                                 border: 'none',         // Removes the border
                                 backgroundColor: 'transparent',  // Makes the background transparent
                                 boxShadow: 'none',      // Removes any box shadow
                                 color: '#000'           // Optionally set the text color
                                 }}
                                 closable
                                 onClose={() => handleRemoveJob(job.id, permission)}
                             >
                                 {job.fullname}
                             </Tag>
                             <br />
                             </React.Fragment>))}
                          <Select
                                key='add-jobs_perms'
                                mode="multiple"
                                placeholder="Add Jobs"
                                value={selectedJobsMap[permission.label]}
                                onChange={(selectedIds) => handleJobsChange(permission.label, selectedIds)}
                                optionLabelProp="label"
                                style={{width: 200, marginTop: 10}}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
                            >
                                {
                                    jobPage.content.map(job=>{
                                        return <Option key={job.id} 
                                                       value={job.id} 
                                                       label={job.name}
                                                       disabled={permission.jobs.some(pJob => pJob.id === job.id)}>
                                            <Space>{job.name}</Space>
                                        </Option>
                                    })
                                }
                            </Select><br/>
                            <Button type="primary" onClick={() => handleAddJobs(permission)} style={{marginTop: 10}} disabled={(selectedJobsMap[permission.label] || []).length === 0}>
                                    Add
                            </Button></>,
                });
            });
        }
        return data;
    } 

    return (
        <Page title="Permissions">
            <PageHeader title={'Permissions'}/>
            <PageContent>
            <Search
                style={{width: 250, marginBottom: 20}}
                // ref={searchInputRef}
                placeholder="Search for permission"
                onSearch={() => dispatch(getOrgMembersPermissions(currentOrg.id, search)).then(foundPage => {setPermissions(foundPage.content)
              }).catch((err) => {
                safeHandleErrorResponse(err)
            })}
                value={search}
                onChange={(value)=>setSearch(value.target.value)}
                />
            <Table 
            columns={columns()} 
            dataSource={data()} 
            pagination={false}
            />        
            <FloatButton.BackTop/>
            </PageContent>
        </Page>
    )
}

export default Permissions;
