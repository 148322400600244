import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../redux/store';
import {useNavigate} from 'react-router-dom';
import {
    Button, Checkbox, Select,
    Space, DatePicker, Input, MenuProps, Dropdown, Col, Flex,
    notification
} from 'antd';
import {
    DeleteOutlined, DownloadOutlined, HddOutlined, MoreOutlined,
    RedoOutlined, ShareAltOutlined, SyncOutlined
} from '@ant-design/icons';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
//
import {useAppContext} from "../../AppContext";
import {createEmptyPage} from '../../services/utils/PageResourceUtils';
// components
import Page from "../../components/page_structure/page";
import PageContent from "../../components/page_structure/page_content";
import PageHeader from '../../components/page_structure/page_header';
import {PageDivider} from '../../components/page_structure/page_divider';
import TestReviewsTable from "../../components/tables/test_reviews_table";
import {change_project_url} from '../../assets/helpers/helper';
import {colors} from '../../assets/data/colors';
import {archiveRun, deleteRun, getRuns, startRun} from '../../redux/actions/run_actions';
// models
import {TestReview} from '../../models/entities/test_reviews';
import {PageResource} from '../../models/dtos/page_resource';
import ButtonC from "../../components/button";
import CancelAction from "../../components/notification/cancel_req";
import RowC from "../../components/row";
import ReviewersAvatars from "../../components/reviewersAvatar";
import ProgressBar from "../../components/progressbar";
import {getRunResultsViaExcel} from "../../redux/actions/reporting_actions";
import { Dayjs } from 'dayjs';
import { Project } from '../../models/entities/project';
import { getProjects } from '../../redux/actions/project_actions';
import TimeHandler from "../../assets/helpers/timeHandler";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

/*  TODO: antD v5 Range Picker */

const {Option} = Select;
const {RangePicker} = DatePicker;
const { Search } = Input;

interface TestReviewsProps {
    personal: boolean;
  }

function TestReviews({ personal }: TestReviewsProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const {currentOrg, currentProject, loggedInUser} = useAppContext();

    const [runPage, setRunPage] = useState<PageResource<TestReview>>(createEmptyPage());

    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [selectedProjectIds, setSelectedProjectIds] = useState<number[]>([]);
    const [selectedState, setSelectedState] = useState<number[]>([1]);
    const [resetButtonState, setResetButtonState] = useState<boolean>(false);
    const [cbShowOnlyMine, setCbShowOnlyMine] = useState<boolean>(true);
    const [loadingButtonState, setLoadingButtonState] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedRange, setSelectedRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
    const [projectPage, setProjectPage] = useState<PageResource<Project>>(createEmptyPage());

    const handleButton = (run_id: number, status: number) => {
        const index = runPage.content.findIndex((item) => item.id === run_id);

        let run = runPage.content[index]
        const data = {
            run_id,
            values:
                {status: status}

        }
        dispatch(startRun(data)).then((response) => {
            run.status = status
            run.disabled_button = 1
            let newPage = {...runPage}
            newPage.content[index] = run
            setRunPage(newPage)

            setTimeout(() => {
                run.disabled_button = 0
                let newPage2 = {...runPage}
                newPage2.content[index] = run
                setRunPage(newPage2)
            }, 5000)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }

    const handleDeleteRun = (id: number) => {
        /*
        * on delete run if a run has serial run notice them that
        * those will turn to manually
        * */
        dispatch(deleteRun(id)).then(() => {
            let new_page = {...runPage}
            new_page.content = new_page.content.filter(item => item.id !== id)

            setRunPage(new_page);
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }

    const handleArchiveRun = (run_id: number, to_state: number) => {
        /*
        only if the run is completed and has unresolved tests throw an alert
        include a button to mark everything as passed
        if the case is still running the add a warning that everything is going to be killed
        * */
        let data: any = {
            run_id,
            values: {
                state: to_state
            }
        }
        if (to_state === 2) {
            data.values.schedule_for = null
            data.values.force_stop = 0
        }

        dispatch(archiveRun(data)).then(() => {
            let new_page = {...runPage}
            if (selectedState.includes(to_state) || selectedState.length === 0) {
                let new_state = {
                    state: to_state
                }
                new_page.content = new_page.content.map(review => {
                    return review.id === run_id ? {...review, ...new_state} : review
                })
            } else {
                new_page.content = new_page.content.filter(item => item.id !== run_id)
            }
            setRunPage(new_page);
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }

    function getActions(review: TestReview): MenuProps {

        let items: MenuProps['items'] = [
            {
                key: 'reportDiv',
                label: 'Reporting',
                disabled: true
            },
            {
                type: 'divider'
            }, {
                key: 'download',
                // label: <DownloadButton fileUrl='' fileName=''/>,
                label: <><DownloadOutlined/> Download Results</>,
                onClick: () => {
                    dispatch(getRunResultsViaExcel(review.id))
                        .then(() => {
                            notification.open({
                                message: <span style={{ color: '#ffffff' }}>Message</span>,
                                description: <span style={{ color: '#ffffff' }}>The Report is being generated. An email will be sent once it is ready.</span>,
                                style: {
                                    // Add your custom styles here
                                    backgroundColor: colors.green.bamboo,
                                    border: '1px solid #d3d3d3',
                                    borderRadius: '10px'
                                  },
                                  duration: 5 // Duration in seconds
                              });
                        }).catch((err) => {
                            safeHandleErrorResponse(err)
                        })
                }
            }, {
                key: 'postDefects',
                label: <><ShareAltOutlined/> Post Defects</>,
                onClick: () => {
                }
            }, {
                key: 'actionsDiv',
                label: 'Actions',
                disabled: true
            }, {
                type: 'divider'
            }, {
                key: 'rerun',
                label: <><RedoOutlined/> Rerun Failed</>,
                disabled: review.progress.passed === review.progress.total,
                onClick: () => {
                }
            }, {
                key: 'rerunAll',
                label: <><RedoOutlined/> Rerun All</>,
                onClick: () => {
                }
            }, {
                key: 'archive',
                label: <><HddOutlined/> {review.state === 1 ? 'Archive' : 'Unarchive'}</>,
                onClick: () => handleArchiveRun(review.id, review.state === 1 ? 2 : 1)
            }, {
                key: 'delete',
                label:
                    <CancelAction action={() => handleDeleteRun(review.id)}>
                        <DeleteOutlined/> Delete
                    </CancelAction>
            }]
        return {items: items}
    }

    const constructTestReview = () => {

        let data: any = [];
        runPage.content.forEach(test_review => {
            data.push({
                key: test_review.id,
                system_status: test_review.system_status,
                runs:
                    <RowC>
                        <Col style={{
                            width: 3,
                            position: 'absolute',
                            borderRadius: '0.5em',
                            top: 35,
                            right: 0,
                            bottom: 25,
                            left: 0,
                            backgroundColor: test_review.has_to_review ?
                                colors.orange.default
                                : colors.green.bamboo
                        }}/>
                        <Col>
                            <h3>{test_review.runs.current}/{test_review.runs.total}</h3>
                            {
                                test_review.state === 2 ? null :
                                    [0, 3].includes(test_review.status) ?
                                        <CancelAction
                                            description={'The button will be available again when the process will finish'}
                                            action={() => handleButton(test_review.id, 1)}>
                                            <Button disabled={test_review.disabled_button === 1} type='text'
                                                    style={{fontSize: 11, color: colors.green.forest}}>
                                                <b>Run Now</b>
                                            </Button>
                                        </CancelAction>
                                        : test_review.status === 4 ?
                                            <CancelAction action={() => handleButton(test_review.id, 1)}>
                                                <Button disabled={test_review.disabled_button === 1} type='text'
                                                        style={{fontSize: 11, color: colors.green.forest}}>
                                                    <b>Resume</b>
                                                </Button>
                                            </CancelAction>
                                            : test_review.status === 1 ?
                                                <CancelAction action={() => handleButton(test_review.id, 4)}>
                                                    <Button disabled={test_review.disabled_button === 1} type='text'
                                                            style={{fontSize: 11, color: colors.red.vermilion}}>
                                                        <b>Stop</b>
                                                    </Button>
                                                </CancelAction>
                                                : null
                            }
                        </Col>
                    </RowC>,
                title:
                    <>
                        <h3 style={{cursor: 'pointer'}}
                            onClick={() => {
                                navigate(change_project_url(`test-review/${test_review.id}`, test_review.project.p_key),
                                    {state: {run: test_review}})
                            }}>
                            {test_review.info.name}
                        </h3>
                        <div style={{color: colors.gray.shadow}}>
                            {test_review.system_status === -10? <b>ERROR: {test_review.failed_reason}<br/></b>: null}
                            {personal && <><span title={test_review.project.name}> Project KEY: {test_review.project.p_key}</span><br/></>}
                            {test_review.state === 2 ? <><span
                                style={{color: colors.red.vermilion, opacity: 0.6}}><b>Archived</b></span><br/></>
                                : <>
                                    <span> {test_review.info.scheduled_for ?
                                        `Sched. ${TimeHandler.getDateToShowJanDDYYHHMM(test_review.info.scheduled_for)}` : 'Not Scheduled'}
                                    </span><br/>
                                </>

                            }
                            <span> Branch: {test_review.branch} </span><br/>
                            <span> By. {test_review.info.created_by.fullname} On. {test_review.info.created_at}</span>
                        </div>
                    </>,
                reviewers:
                    <ReviewersAvatars
                        reviewers={{reviewers: test_review.reviewers, total_reviewers: test_review.total_reviewers}}
                        // avatarSize={'large'}
                    />,
                progress:
                    <>
                        <ProgressBar
                            options={[{
                                title: 'Passed',
                                color: colors.green.forest,
                                progress: test_review.progress.passed
                            }, {
                                title: 'Failed',
                                color: colors.red.vermilion,
                                progress: test_review.progress.failed
                            }, {
                                title: 'Unresolved',
                                color: colors.orange.default,
                                progress: test_review.progress.unresolved
                            }]}
                            total={test_review.progress.total}
                            bar_width={'90%'}
                        />
                        <div style={{color: '#b1b1b1'}}>
                            Estimation: {(test_review.progress.total - test_review.progress.failed - test_review.progress.passed) * 5}m {test_review.progress.new_cases ? `+ ${test_review.progress.new_cases} New Case${test_review.progress.new_cases > 1 ? 's' : ''}` : ''}
                        </div>
                    </>
                , //Tags view table, getAllJobsForAUser()
                actions:
                    <Dropdown
                        menu={getActions(test_review)}
                        trigger={['click']}
                        arrow>
                        <ButtonC
                            onClick={() => {
                            }}
                            icon={<MoreOutlined style={{color: colors.green.forest}}/>}
                            size='small'
                            shape='circle'
                        />
                    </Dropdown>
            });

        });

        return data;
    }

    const onChangeCBShowOnlyMine = (e: CheckboxChangeEvent) => {
        setCbShowOnlyMine(e.target.checked)
    };

    const handleReset = () => {
        setResetButtonState(true)
        setSelectedStatuses([])
        setSelectedProjectIds([])
        setSelectedState([1])
        setLoadingButtonState(false)
        setSearchTerm('')
        setSelectedRange([null, null])
    }

    const handleStatusChange = (event: string[]) => {
        setSelectedStatuses(event);
    };

    const handleProjectChange = (event: number[]) => {
        setSelectedProjectIds(event);
    };

    const handleStateChange = (event: number[]) => {
        setSelectedState(event);
    };

    const handleRangeChange = (dates: any) => {
        setSelectedRange(dates);
    };

    const handleQuery = () => {
        dispatch(getRuns(
            {
                project_ids: personal? selectedProjectIds: [currentProject.id],
                org_id: currentOrg.id,
                search: searchTerm,
                status: selectedStatuses,
                state: selectedState,
                created_from: selectedRange ? selectedRange[0]?.valueOf().toString().slice(0, -3) : null,
                created_to: selectedRange ? selectedRange[1]?.valueOf().toString().slice(0, -3) : null,
                assignees: personal || cbShowOnlyMine ? [loggedInUser.id] : [],
                current_user: loggedInUser.id
            }))
            .then(foundPage=>{
                setRunPage(foundPage)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    useEffect(() => {
        handleQuery()
    }, [dispatch, selectedStatuses, selectedProjectIds, cbShowOnlyMine, selectedState, selectedRange, currentProject, currentOrg])

    useEffect(() => {
        const fetchData = () => {
                dispatch(getProjects({org_id:currentOrg.id, user_id:loggedInUser.id}))
                    .then(found_projects =>{
                    setProjectPage(found_projects)
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
        };
            fetchData();
    }, [dispatch, currentOrg, currentProject]);

    return (
        <Page title="Test Review">
            <PageHeader
                title="Test Review"
                buttonHeader={personal? null:{
                    type: "nav",
                    onClickFunction: () => change_project_url("run")
                }}/>

            <PageContent>
                {
                    personal?
                        null
                        : <RowC>
                            <Checkbox checked={cbShowOnlyMine}
                                      onChange={onChangeCBShowOnlyMine}>Show Only My Runs To Review</Checkbox>
                        </RowC>
                }

            <RowC style={{ marginTop: 10 }}>
                <Space wrap>
                    {personal && (
                        <Select
                            key="select-project"
                            mode="multiple"
                            style={{ minWidth: '130px', flex: '1 1 130px', marginRight: 10 }}
                            placeholder="Select Project"
                            value={selectedProjectIds}
                            onChange={handleProjectChange}
                            optionLabelProp="label"
                        >
                            {projectPage.content.map((project) => (
                                <Option key={project.id} value={project.id} label={project.name}>
                                    <Space>{project.name}</Space>
                                </Option>
                            ))}
                        </Select>
                    )}

                    <Select
                        mode="multiple"
                        style={{ minWidth: '130px', flex: '1 1 200px' }}
                        placeholder="Select Status"
                        value={selectedStatuses}
                        onChange={handleStatusChange}
                        optionLabelProp="label"
                    >
                        <Option value="0" label="Pending">
                            <Space>Pending</Space>
                        </Option>
                        <Option value="1" label="Running">
                            <Space>Running</Space>
                        </Option>
                        <Option value="2" label="Completed">
                            <Space>Completed</Space>
                        </Option>
                        <Option value="3" label="Scheduled">
                            <Space>Scheduled</Space>
                        </Option>
                    </Select>

                    <Select
                        mode="multiple"
                        style={{ minWidth: '130px', flex: '1 1 200px', marginLeft: 10 }}
                        placeholder="Select State"
                        value={selectedState}
                        onChange={handleStateChange}
                        optionLabelProp="label"
                    >
                        <Option value={1} label="Active">
                            <Space>Active</Space>
                        </Option>
                        <Option value={2} label="Archived">
                            <Space>Archived</Space>
                        </Option>
                    </Select>

                    <RangePicker
                        style={{ minWidth: '200px', flex: '1 1 250px', marginLeft: 10 }}
                        onChange={handleRangeChange}
                        value={selectedRange}
                    />

                    <Search
                        style={{ minWidth: '200px', flex: '1 1 250px', marginLeft: 10 }}
                        placeholder="Search for run"
                        onSearch={handleQuery}
                        value={searchTerm}
                        onChange={(value) => setSearchTerm(value.target.value)}
                    />
                    <Button
                            icon={<SyncOutlined />}
                            loading={loadingButtonState}
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                </Space>
            </RowC>
                <PageDivider/>
                <TestReviewsTable data={constructTestReview()}/>
            </PageContent>
        </Page>
    )
}

export default TestReviews;