import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Checkbox, Form, Select, Tooltip } from 'antd';
import { FormInstance } from "antd/lib/form";
import { PageResource } from "../../models/dtos/page_resource";
import { deleteJob } from "../../redux/actions/job_actions";
import {createComponent, updateComponent} from '../../redux/actions/component_actions';
import { deleteFromArray } from '../../assets/helpers/helper';
import ModalC from "../modal";
import ButtonC from "../button";
import InputC from "../input";
import {useAppContext} from "../../AppContext";
import {Component, NewComponent} from "../../models/entities/component";
import {empty_component} from "../../services/EmptyEntities/EmptyComponent";
import { User } from "../../models/entities/user";
import { createEmptyPage } from "../../services/utils/PageResourceUtils";
import { getUserOrgRole, getUsers } from "../../redux/actions/user_actions";
import { colors } from "../../assets/data/colors";
import { InfoCircleOutlined } from "@ant-design/icons";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";
const { Option } = Select;


type ComponentProps = {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    componentPage: PageResource<Component>;
    setComponentPage: Dispatch<PageResource<Component>>;
    selectedComponent: Component;
    setSelectedComponent: Dispatch<SetStateAction<Component>>;
    copy?: boolean;
    setCopy?: Dispatch<SetStateAction<boolean>>;
    hideGlobal?: boolean
}

export function ComponentModal(props: ComponentProps):JSX.Element{
    //props    
    const {setVisible, visible, setComponentPage, selectedComponent, setSelectedComponent, componentPage, copy, setCopy, hideGlobal} = props;
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg, currentProject, loggedInUser} = useAppContext();
    // form state
    const [componentSize, setComponentSize] = useState();
    const [form] = Form.useForm();

    const icon_style = {color: colors.green.bamboo, fontSize:18}


    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [addUserAction, setAddUserAction] = useState<PageResource<User>>(createEmptyPage());
    const [owner, setOwner] = useState<{id: number, fullname: string}>({id: loggedInUser.id, fullname: loggedInUser.name + " " + loggedInUser.lastname});
    const [global, setGlobal] = useState<boolean | undefined>(false);
    const [role, setRole] = useState<number>();

    const org_id = currentOrg.id

    useEffect(() => {
        if (selectedComponent !== empty_component) {
            setTitle(selectedComponent.title);
            setDescription(selectedComponent.description);
            setOwner(JSON.parse(JSON.stringify(selectedComponent.owner)));
            setGlobal(!selectedComponent.project_id);
        } else {
            setTitle('');
            setDescription('');
            setOwner({id: loggedInUser.id, fullname: loggedInUser.name + " " + loggedInUser.lastname});
            setGlobal(false);
        }
    }, [selectedComponent]);

    useEffect(() => {
        dispatch(getUserOrgRole(loggedInUser.id, currentOrg.id)).then(role => {
            setRole(role.role)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }, [loggedInUser, currentOrg])

    useEffect(() => {
        form.setFieldsValue({
          title: title,
          description: description,
          owner: owner,
          global: global
        });
      }, [form, title, description, owner, global]);

    const constructAddUsers = () => {
        return addUserAction.content.map(user => {
            const optionValue = JSON.stringify({id: user.id, fullname: user.name + " " + user.lastname}); // Convert object to string
            return <Option key={user.id} value={optionValue} label={user.name + " " + user.lastname} style={user.id === owner.id ? {color: colors.green.forest} : {color: 'black'}}>
                        {user.name + " " + user.lastname}
                   </Option>
        });
    }

    const handleAddUsersChange = (event: {id: number, fullname: string}) => {
        setOwner(event);
    };

    const handleAddUsers = () => {
        dispatch(getUsers({project_id: currentProject.id, org_id: org_id}))
            .then(users => setAddUserAction(users)).catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    const onCreate = (values:any, more: boolean) => {

        if (!selectedComponent.id){

        }else{
            const new_component:NewComponent = {
                project_id: !global ? values.org_component? undefined: currentProject.id : null,
                owner: JSON.parse(values.component_owner).id,
                creator: loggedInUser.id,
                title: values.title,
                description: values.description,
                org_id: org_id,
                global: hideGlobal ? true : global
            }
            dispatch(createComponent(new_component, componentPage.content))
                .then(foundPage => {componentPage.content=foundPage
                    setComponentPage(componentPage)}).catch((err) => {
                        safeHandleErrorResponse(err)
                    })
        }
        setTitle('');
        setDescription('');
        setGlobal(false)
        setOwner({id: loggedInUser.id, fullname: loggedInUser.name + " " + loggedInUser.lastname});
        if (setCopy) {setCopy(false)}
        setTimeout(() => {
            setVisible(more)
        }, 500);
    }

    const onEdit = (values:any) => {

        if (!selectedComponent.id){

        }else{
            const edit_component:Component = {
                id: selectedComponent.id,
                project_id: !global ? currentProject.id : null,
                owner_id: JSON.parse(values.component_owner).id,
                title: values.title,
                description: values.description,
                org_id: org_id,
                owner: JSON.parse(values.component_owner),
                cases: selectedComponent.cases,
                global: hideGlobal ? true : global
            }
            dispatch(updateComponent(edit_component, componentPage.content))
                .then(() => {
                    setComponentPage(componentPage)
                    setSelectedComponent(empty_component)}).catch((err) => {
                        safeHandleErrorResponse(err)
                    })
        }
        setTitle('');
        setDescription('');
        setGlobal(false)
        setOwner({id: loggedInUser.id, fullname: loggedInUser.name + " " + loggedInUser.lastname});
        if (setCopy) {setCopy(false)}
        setTimeout(() => {
            setVisible(false)
        }, 500);
    }

    const onCopy = (values:any) => {

        if (!selectedComponent.id){

        }else{
            const new_component:NewComponent = {
                project_id: !global ? values.org_component? undefined: currentProject.id : null,
                owner: JSON.parse(values.component_owner).id,
                creator: loggedInUser.id,
                title: values.title,
                description: values.description,
                org_id: org_id,
                global: hideGlobal ? true : global,
                origin_component_id: selectedComponent.id
            }
            dispatch(createComponent(new_component, componentPage.content))
                .then(foundPage => {componentPage.content=foundPage
                    setComponentPage(componentPage)}).catch((err) => {
                        safeHandleErrorResponse(err)
                    })
        }
        setTitle('');
        setDescription('');
        setGlobal(false)
        setOwner({id: loggedInUser.id, fullname: loggedInUser.name + " " + loggedInUser.lastname});
        if (setCopy) {setCopy(false)}
        setTimeout(() => {
            setVisible(false)
        }, 500);
    }

    const handleOk = (form:FormInstance<any>, more: boolean) => {
        form
            .validateFields()
            .then(values => {
                // form.resetFields();
                selectedComponent===empty_component ? onCreate(values, more) : copy ? onCopy(values) : onEdit(values)
        })
            .catch((err) => {safeHandleErrorResponse(err)})

    }

    const handleCancel = () => {
        setTitle('');
        setDescription('');
        setGlobal(false)
        setOwner({id: loggedInUser.id, fullname: loggedInUser.name + " " + loggedInUser.lastname});
        setVisible(false)
        if (setCopy) {setCopy(false)}
        setSelectedComponent(empty_component)
    };

    const handleDelete = (job_id:number) => {
        dispatch(deleteJob(job_id))
            .catch((err) => {safeHandleErrorResponse(err)})

        const jobs:Component[] = componentPage.content
        const job_to_delete = jobs.find(job => job.id === job_id);
        if(job_to_delete){
            const index = jobs.indexOf(job_to_delete)
            componentPage.content = deleteFromArray(jobs, index);
            setComponentPage(componentPage)
        }
        setVisible(false)
    }

    const onFormLayoutChange = ({ size } :any) => {
        setComponentSize(size);
    };

    const footer_buttons = [
        <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>,
        selectedComponent === empty_component && <ButtonC key="submit_ok_more" type="primary" onClick={() => handleOk(form, true)} text={'Create and add More'} 
        disabled={title.length < 2 || (componentPage.content?.some(item => item.title === title) && selectedComponent?.title !== title)}/>,
        <ButtonC key="submit_ok" type="primary" onClick={() => handleOk(form, false)} text={selectedComponent === empty_component ? 'Create' : copy ? 'Copy' : 'Save'} 
        disabled={title.length < 2 || (componentPage.content?.some(item => item.title === title) && selectedComponent?.title !== title)}/>
    ];

    return <ModalC 
            open={visible}
            title={selectedComponent === empty_component ? 'Add New Component' : copy ? 'Copy Component' : 'Edit Component'}
            onOk={() => handleOk(form, false)}
            onCancel={handleCancel}
            footer={selectedComponent === empty_component || copy ? footer_buttons:[
                <ButtonC key="submit_delete_component" type="primary" style={{float:'left'}} danger onClick={()=>handleDelete(selectedComponent.id)} text="Delete"/>
                , ...footer_buttons]}>
                    {<Form form={form}
                            name="createComponent"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            layout="horizontal"
                            initialValues={{ size: componentSize }}
                            onValuesChange={onFormLayoutChange}
                            size={componentSize}
                        >
                        {componentPage.content?.some(item => item.title === title) && selectedComponent?.title !== title && <p style={{color: '#F6C324'}}>The specific Component already exists!</p>}
                        <Form.Item name="title" label="Title" >
                            <InputC value={title}
                                            onChange={(event) => setTitle(event.target.value)}
                                            placeholder="Enter Component Title"/>
                        </Form.Item>
                        <Form.Item name="description" label="Description">
                            <InputC value={description} 
                                            onChange={(event) => setDescription(event.target.value)} 
                                            placeholder="Enter A Short Component Description"/>
                        </Form.Item>
                        <Form.Item name="component_owner" label="Owner:">
                        <Select
                            style={{width: 315 }}
                            placeholder="Select Owner"
                            optionLabelProp="label"
                            onChange={handleAddUsersChange}
                            onClick={handleAddUsers}
                            value={owner}
                            >
                    {constructAddUsers()}
                    </Select>
                        </Form.Item>
                        {(role === 1 && !hideGlobal) &&
                        <Form.Item name="org_component" label="Global: ">
                            <Checkbox onChange={() => setGlobal(!global)} checked={global}/>
                            <Tooltip
                                placement="right"
                                title={
                                        <>
                                            <h3>Info</h3>
                                            <p> By checking then the Component will be visible in the entire Organization.
                                                {/* <br/><br/>
                                                <strong>Note: </strong> This option is visible only to Organization Owner. */}
                                                </p>
                                        </>
                                        }
                                        color={colors.green.bamboo}
                                    >
                                        <InfoCircleOutlined style={Object.assign({}, {marginLeft:15}, icon_style)}/>
                                    </Tooltip>
                            
                        </Form.Item>}
                    </Form>}
                </ModalC>
}