import React from 'react';
import { Card } from "antd";
import Meta from "antd/lib/card/Meta";
import { CardItem } from '../../models/entities/helpers/cardItem'
import { Link } from "react-router-dom";

export function CardIcon(item:CardItem, key:any):JSX.Element{
    const wrap = (children:React.ReactNode, link?:string) => {
        return link? <Link to={link}>{children}</Link>: <>{children}</>
    }

    return <Card
        key={key}
        hoverable={true}
        onClick={item.onclick}
        style={item.style? item.style
            :{textAlign: "center", marginLeft: "10px", marginTop: "20px"}
        }
        cover={wrap(typeof item.icon === 'string' ?
            <img src={item.icon} alt={item.description}/> : item.icon, item.link)}
        actions={item.actions}
    >
        <div style={item.bodyStyle}>
            <Meta title={item.name} description={item.description} />
        </div>
        {/*<Meta title={item.name} description={item.description} />*/}
    </Card>
}

export function CardIcons(items:CardItem[]):JSX.Element{

    return (
        <>
            {items.map((item, key)=>{
                return CardIcon(item, key)
            })}
        </>
    )
}