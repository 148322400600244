import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from 'antd';
import { FormInstance } from "antd/lib/form";
import { deleteFromArray } from '../../assets/helpers/helper';
import ModalC from "../modal";
import ButtonC from "../button";
import InputC from "../input";
import {useAppContext} from "../../AppContext";
import { ModuleSettings, saveProjectModulesParams } from "../../models/entities/settings";
import { empty_module } from "../../services/EmptyEntities/EmptyModal";
import { deleteProjectModule, saveProjectModules } from "../../redux/actions/project_actions";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";


type ModuleProps = {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    modulePage: ModuleSettings[];
    setModulePage: Dispatch<ModuleSettings[]>;
    selectedModule: ModuleSettings;
    setSelectedModule: Dispatch<SetStateAction<ModuleSettings>>;
}

export function ModuleModal(props: ModuleProps):JSX.Element{
    //props    
    const {setVisible, visible, modulePage, setModulePage, selectedModule, setSelectedModule} = props;
    const dispatch: AppDispatch = useDispatch();

    const {currentProject} = useAppContext();
    // form state
    const [componentSize, setComponentSize] = useState();
    const [form] = Form.useForm();

    const [title, setTitle] = useState<string>();
    const [version, setVersion] = useState<string>();
    const [order, setOrder] = useState<number>();

    useEffect(() => {
        if (selectedModule !== empty_module) {

            setTitle(selectedModule.title);
            setVersion(selectedModule.version);
            setOrder(selectedModule.module_order);
        } else {
            setTitle('');
            setVersion('');
            setOrder(-1)
        }
    }, [selectedModule]);

    useEffect(() => {
        form.setFieldsValue({
          title: title,
          version: version,
          order: order
        });
      }, [form, title, order]);

      const onCreate = (more: boolean) => {
        if (!selectedModule.id) {
            // Handle case where selectedModule.id is not set
        } else {
            const new_module = {
                project_id: currentProject.id,  // Assuming currentProject.id is defined elsewhere
                title: title,
                version: version,
                module_order: modulePage.length + 1
            };

            // Example of dispatching an action (assuming using Redux)
            dispatch(saveProjectModules(new_module))
                .then((found) => {
                    const updated_modules = [...modulePage, found.content[0]];  // Use spread operator to create a new array
                    setModulePage(updated_modules);
                    setSelectedModule(empty_module);  // Assuming empty_module is defined elsewhere
                })
                .catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }

        setTitle('');
        setVersion('');
        setOrder(-1);

        setTimeout(() => {
            setVisible(more);
        }, 500);
    };


    const onEdit = (values:any) => {

        if (!selectedModule.id){

        }else{
            const edit_module:saveProjectModulesParams = {
                project_module_id: selectedModule.id,
                project_id: currentProject.id,
                title: title,
                version: version,
                order: values.order
            }
            dispatch(saveProjectModules(edit_module))
                .then(() => {
                    const updated_modules: any = modulePage.map(module => module.id === selectedModule?.id ? edit_module : module)
                    setModulePage(updated_modules)
                    setSelectedModule(empty_module)}).catch((err) => {
                        safeHandleErrorResponse(err)
                    })
        }
        setTitle('');
        setVersion('');
        setOrder(-1)
        setTimeout(() => {
            setVisible(false)
        }, 500);
    }

    const handleOk = (form:FormInstance<any>, more: boolean) => {
        form
            .validateFields()
            .then(values => {
                // form.resetFields();
                selectedModule===empty_module ? onCreate(more) : onEdit(values)
        })
        .catch(() => {
            // console.log('Validate Failed:', info);
        })
    }

    const handleCancel = () => {
        setTitle('');
        setVersion('');
        setOrder(-1)
        setVisible(false)
        setSelectedModule(empty_module)
    };

    const handleDelete = (module_id:any) => {
        dispatch(deleteProjectModule(currentProject.id, [module_id]))
            .catch((err) => {safeHandleErrorResponse(err)})

        const modules: ModuleSettings[] = modulePage
        const module_to_delete = modules.find(module => module.id === module_id);
        if(module_to_delete){
            const index = modules.indexOf(module_to_delete)
            const Updated_modules = deleteFromArray(modules, index);
            setModulePage(Updated_modules)
        }
        setVisible(false)
    }

    const onFormLayoutChange = ({ size } :any) => {
        setComponentSize(size);
    };

    const footer_buttons = [
        <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>,
        selectedModule === empty_module && <ButtonC key="submit_ok_more" type="primary" onClick={() => handleOk(form, true)} text={'Create and add More'} 
        disabled={title === undefined || title === null || title === '' || version === undefined || version === null || version === ''|| (modulePage?.some(item => item.title === title) && selectedModule?.title !== title)}/>,
        <ButtonC key="submit_ok" type="primary" onClick={() => handleOk(form, false)} text={selectedModule === empty_module ? 'Create' : 'Save'} 
        disabled={title === undefined || title === null || title === '' || version === undefined || version === null || version === ''|| (modulePage?.some(item => item.title === title) && selectedModule?.title !== title)}/>
    ];

    return <ModalC 
            open={visible}
            title={selectedModule === empty_module ? 'Add New Module' : 'Edit Module'} 
            onOk={() => handleOk(form, false)}
            onCancel={handleCancel}
            footer={selectedModule === empty_module? footer_buttons:[
                <ButtonC key="submit_delete_module" type="primary" style={{float:'left'}} danger 
                         onClick={()=>handleDelete(selectedModule.id)} text="Delete"/>
                , ...footer_buttons]}>
                    {<Form form={form}
                            name="createModule"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            layout="horizontal"
                            initialValues={{ size: componentSize }}
                            onValuesChange={onFormLayoutChange}
                            size={componentSize}
                        >
                        {modulePage?.some(item => item.title === title) && selectedModule?.title !== title && <p style={{color: '#F6C324'}}>The specific Module already exists!</p>}
                        <Form.Item name="title" label="Module" >
                            <InputC value={title}
                                    onChange={(event) => setTitle(event.target.value)}
                                    placeholder="Type Module"/>
                        </Form.Item>
                        <Form.Item name="version" label="Version">
                            <InputC value={version} 
                                    onChange={(event) => setVersion(event.target.value)} 
                                    placeholder="Type Version"/>
                        </Form.Item>
                    </Form>}
                </ModalC>
}
