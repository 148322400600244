import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import {useNavigate} from 'react-router-dom';
import {
    Button,
    Dropdown,
    FloatButton,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Select,
    Space,
    Switch,
    Table
} from 'antd';
import {ModuleSettings, setting_sections} from '../../models/entities/settings';
import Settings from '../../components/settings';
import {
    deleteProject,
    deleteProjectModule,
    getAddProjectSettingsActions,
    getOrgProjectKeys,
    getProjectGithubIntegrations,
    getProjectModules,
    getProjectProgrammingLanguages,
    getProjectSettings,
    getProjectTypes,
    getProjectUsers,
    getSystemProgrammingLanguages,
    saveProjectModules,
    updateProject,
    updateProjectGithubIntegration,
    updateProjectNotificationSetting,
    updateProjectOwner,
    updateProjectProgrammingLanguages,
    updateProjectRunSettings
} from '../../redux/actions/project_actions';
import {AppDispatch} from "../../redux/store";
import ButtonC from "../../components/button";
import UploaderC from "../../components/uploaders/uploaderC";
import {useAppContext} from "../../AppContext";
import {User} from '../../models/entities/user';
import {empty_project} from "../../services/EmptyEntities/EmptyProject";
import CheckboxC from "../../components/checkbox";
import {CheckCircleOutlined, DeleteOutlined, DownOutlined, EditOutlined, HolderOutlined} from '@ant-design/icons';
import {ProjectTypes} from '../../models/entities/project';
import {colors} from '../../assets/data/colors';
import Slacks from '../../components/tables/slack_table';
import './project_settings';
import CancelAction from '../../components/notification/cancel_req';
import {ModuleModal} from '../../components/modals/modules_modal';
import {empty_module} from '../../services/EmptyEntities/EmptyModal';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';


const {Option} = Select;


function ProjectSettings(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const {
        currentOrg, currentProject, currentProjectSettings,
        setAppContextProject, setAppContextProjectSettings
    } = useAppContext();

    const org_id = currentOrg.id
    const project_id = currentProject.id
    const params = {org_id, project_id}

    const [projectTitle, setProjectTitle] = useState<string>(currentProject.name);
    const [projectKey, setProjectKey] = useState<string>(currentProject.p_key);
    const [addProjectUsers, setAddProjectUsers] = useState<number[]>([]);
    const [runParallel, setRunParallel] = useState<number>(20);
    const [runBranch, setRunBranch] = useState<string>("master");
    const [rerun, setRerun] = useState<number>(2);
    const [showKeyError, setShowKeyError] = useState(false);
    const [maxParallelIsChecked, setMaxParallelIsChecked] = useState(false);
    const [savedProjectTypes, setSavedProjectTypes] = useState<ProjectTypes[]>([]);
    const [savedProjectOwners, setSavedProjectOwners] = useState<User[]>([]);
    const [projectVisibility, setProjectVisibility] = useState<boolean>();
    const [projectOwner, setProjectOwner] = useState<number>();
    const [projectOwnerName, setProjectOwnerName] = useState<any>();
    const [projectType, setProjectType] = useState<number>();
    const [moduleType, setModuleType] = useState<string>('0');
    const [projectState, setProjectState] = useState<number>(1);
    const [projectStateName, setProjectStateName] = useState<string>("");
    const [projectStateStatus, setProjectStateStatus] = useState<string>("");
    const [projectTypeName, setProjectTypeName] = useState<any>();
    const [beforeRunNotif, setBeforeRunNotif] = useState<boolean>();
    const [afterRunNotif, setAfterRunNotif] = useState<boolean>();
    const [githubWarnings, setGithubWarnings] = useState<boolean>();
    const [caseAssignmentNotif, setCaseAssignmentNotif] = useState<boolean>();
    const [caseUnassignmentNotif, setCaseUnassignmentNotif] = useState<boolean>();
    const [checkedModules, setCheckedModules] = useState<any[]>([])
    const [rows, setRows] = useState<ModuleSettings[]>([]);
    const [githubUser, setGithubUser] = useState<string>();
    const [githubTokenName, setGithubTokenName] = useState<string>();
    const [githubTokenValue, setGithubTokenValue] = useState<string>();
    const [githubRepoUrl, setGithubRepoUrl] = useState<string>();
    const [githubRepoName, setGithubRepoName] = useState<string>();
    const [githubRepoPath, setGithubRepoPath] = useState<string>();
    const [currentPL, setCurrentPL] = useState<{ id: number, title: string } | any>();
    const [plVersion, setPLVersion] = useState<string>();
    const [defaultVersion, setDefaultVersion] = useState<string>();
    const [savedPL, setSavedPL] = useState<{ id: number, title: string }[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [gitHubRepoNameFlag, setGitHubRepoNameFlag] = useState<boolean>(true);
    const [selectedModule, setSelectedModule] = useState<ModuleSettings>(empty_module);
    const [projectKeys, setProjectKeys] = useState<string[]>([]);

    useEffect(() => {
        dispatch(getProjectUsers(params))
            .then(users => {
                const data: number[] = users.content.map(user => {
                    return user.id
                })
                setAddProjectUsers(data)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getProjectModules(currentProject.id))
            .then(settings => {
                setRows(settings.content)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getSystemProgrammingLanguages())
            .then(pls => {
                setSavedPL(pls.content)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getProjectGithubIntegrations(currentOrg.id, currentProject.id))
            .then(settings => {
                setGithubUser(settings.content.integration_user);
                setGithubTokenName(settings.content.token_name);
                setGithubTokenValue('');
                setGithubRepoUrl(settings.content.repo_url);
                setGithubRepoName(settings.content.repo_name);
                setGithubRepoPath(settings.content.repo_path);
                setGithubWarnings(settings.content.github_warnings);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getAddProjectSettingsActions({project_id}))
            .then(settings => {
                setRunParallel(settings.parallels)
                setRunBranch(settings.branch)
                setRerun(settings.runs_number)

                currentProjectSettings.run_settings.project_id = project_id
                currentProjectSettings.run_settings.branch = settings.branch
                currentProjectSettings.run_settings.runs_number = settings.runs_number
                currentProjectSettings.run_settings.parallels = settings.parallels
                setAppContextProjectSettings(currentProjectSettings)

                if (settings.parallels === -1) {
                    setRunParallel(20)
                    setMaxParallelIsChecked(true)
                }
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getOrgProjectKeys(currentOrg.id))
            .then(keys => {
                setProjectKeys(keys)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getProjectSettings(params))
            .then(settings => {
                setProjectVisibility(settings.project_visible);
                setProjectOwner(settings.owner_id);
                setProjectOwnerName(settings.owner_name + " " + settings.owner_lastname);
                setProjectType(settings.project_type_id);
                setProjectState(settings.project_state);
                setProjectTypeName(settings.project_type_title);
                setBeforeRunNotif(settings.before_run);
                setAfterRunNotif(settings.after_run);
                setCaseAssignmentNotif(settings.case_assignment);
                setCaseUnassignmentNotif(settings.case_unassignment);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

    }, [dispatch, currentProject])


    useEffect(() => {
        dispatch(getProjectProgrammingLanguages(currentProject.id))
            .then(settings => {
                setPLVersion(settings.content ? settings.content.version : '')
                const selectedPL = savedPL.find(item => item.id === (settings.content?.id ?? 0));
                if (selectedPL) {
                    setCurrentPL({id: selectedPL.id, title: selectedPL.title});
                }
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }, [dispatch, savedPL])

    useEffect(() => {
        // General function to fetch the latest version tag from a GitHub repository
        const fetchLatestVersion = async (repo: string, formatFunc: any, setVersion: any) => {
            try {
                const response = await fetch(`https://api.github.com/repos/${repo}/tags`);
                const data = await response.json();
                if (data.length > 0) {
                    const latestTag = data[0].name;
                    const formattedVersion = formatFunc(latestTag);
                    setVersion(formattedVersion);
                }
            } catch (error) {
                console.error(`Error fetching the latest version for ${repo}:`, error);
            }
        };

        // Fetch the latest Python version
        currentPL?.title === 'Python' ?
            fetchLatestVersion('python/cpython', formatPythonVersion, setDefaultVersion) :
            // Fetch the latest Java version
            fetchLatestVersion('openjdk/jdk', formatJavaVersion, setDefaultVersion);
    }, [dispatch, currentPL]);

    const formatPythonVersion = (version: string) => {
        const match = version.match(/(\d+\.\d+\.\d+)/);
        return match ? match[0] : version;
    };

    const formatJavaVersion = (version: string) => {
        const match = version.match(/(\d+)(?:[+-]\d+)?/);
        return match ? match[1] : version;
    };

    useEffect(() => {
        setProjectStateName(projectState === 1 ? "Archive" : "Active");
        setProjectStateStatus(projectState === 1 ? "Activated" : "Archived");
    }, [projectState])

    const project_sdk = () => {
        return <Form name="complex-form" onFinish={() => {
            // console.log('onFinish')
        }}>
            <Form.Item label="">
                <Space.Compact>
                    <Form.Item
                        name={['address', 'province']}
                        noStyle
                        rules={[{required: true, message: 'Province is required'}]}
                    >
                        <Select
                            placeholder="Select Language"
                            style={{width: 150}}
                            defaultValue={currentPL?.title}
                            onChange={handleUpdatePLChange}>
                            {constructPL()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={['address', 'street']}
                        noStyle
                        rules={[{required: true, message: 'Type the exact Version'}]}
                    >
                        <Input
                            style={{width: 170}}
                            placeholder={defaultVersion}
                            value={plVersion || plVersion !== null ? plVersion : ''}
                            onChange={handlePLVersion}/>
                        <Button
                            type='default'
                            disabled={Boolean(plVersion)}
                            onClick={handleUpdatePL}>Set
                        </Button>
                    </Form.Item>
                </Space.Compact>
            </Form.Item>
        </Form>
    }

    // Handle drag-and-drop functionality
    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, id: number) => {
        e.dataTransfer.setData('id', id.toString());
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, targetId: number) => {
        e.preventDefault();
        const droppedId = parseInt(e.dataTransfer.getData('id'), 10);
        const draggedRow = rows.find(row => row.id === droppedId);

        if (draggedRow) {
            const updatedRows = rows.filter(row => row.id !== droppedId);
            const dropIndex = updatedRows.findIndex(row => row.id === targetId);

            // Adjust the drop index if moving downward
            const targetIndex = rows.findIndex(row => row.id === targetId);
            const currentIndex = rows.findIndex(row => row.id === droppedId);
            const adjustedDropIndex = targetIndex > currentIndex ? dropIndex + 1 : dropIndex;

            updatedRows.splice(adjustedDropIndex, 0, draggedRow);

            // Update order
            const reorderedRows = updatedRows.map((row, index) => ({...row, order: index + 1}));
            //   setRows(reorderedRows);

            // Save the new order to the server
            dispatch(saveProjectModules({
                project_id: currentProject.id,
                project_module_id: droppedId,
                module_order: adjustedDropIndex + 1
            }))
                .then(() => {
                    setRows(reorderedRows);
                })
                .catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }
    };

    const handleDeleteModules = () => {
        dispatch(deleteProjectModule(currentProject.id, checkedModules)).then(() => {
            let updatedList = rows.filter((item => !checkedModules.includes(item.id)));
            setRows(updatedList);
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }

    const columns: any = [
        {
            title: 'Module',
            dataIndex: 'title',
            key: 'title',
            render: (text: string, row: ModuleSettings) => (
                <div
                    key={row.id}
                    id={`${row.id}`}  // Convert row.id to string
                    draggable
                    onDragStart={(e) => handleDragStart(e, row.id !== undefined ? row.id : 0)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, row.id !== undefined ? row.id : 0)}
                    style={{display: 'flex', alignItems: 'center', cursor: 'move'}}
                >
                    <HolderOutlined/>
                    <span style={{marginLeft: '5px'}}>{text}</span>
                </div>
            ),
        },
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: (
                <>
                    <Dropdown
                        disabled={checkedModules.length === 0}
                        menu={{
                            items: [{
                                key: `mass-delete`,
                                label: <CancelAction secs={2}
                                                     action={handleDeleteModules}><DeleteOutlined/> Delete</CancelAction>,
                            }]
                        }}
                    >
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <Space>
                                Actions <DownOutlined/>
                            </Space>
                        </a>
                    </Dropdown>
                    <div style={{fontSize: 11}}>Selected {checkedModules.length}</div>
                </>
            ),
            dataIndex: 'actions',
            key: 'actions',
        },
    ];

    const handleAddRow = () => {
        setVisible(true)
    };

    const dataSource = rows.map(row => ({
        id: row.id, // Ensure row.id exists if it's a required field in Row
        title: row.title,
        version: row.version,
        module_order: row.module_order, // Corrected typo here
        key: row.id, // key is required for Table's dataSource
        actions: <><EditOutlined onClick={() => {
            setVisible(true);
            setSelectedModule(row)
        }}/> Edit </>
    }));

    const custom_modules = () => {
        return (
            <div>
                <Table
                    columns={columns}
                    style={{width: 370}}
                    dataSource={dataSource}
                    rowKey="id"
                    pagination={false}
                    rowSelection={{
                        selectedRowKeys: checkedModules,
                        onChange: setCheckedModules,
                    }}
                />
                <div style={{marginBottom: '16px'}}>
                    <Button type="primary" onClick={handleAddRow} style={{marginTop: 10}}>
                        Add
                    </Button>
                </div>
            </div>
        );
    };

    const handleDelete = () => {
        const confirmation = window.confirm('Are You Sure?')

        if (confirmation) {
            dispatch(deleteProject(currentProject.id, currentOrg.id)).then(value => {
                if (value === 200) {
                    setAppContextProject(empty_project);
                    navigate(`/${currentOrg.domain}/projects`)
                    // window.location.reload();
                    // delete all the other project related data
                }
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
    }

    const handleProjectTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProjectTitle(event.target.value);
    };

    const handleGithubUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGithubUser(event.target.value);
    };

    const handleGithubTokenName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGithubTokenName(event.target.value);
    };

    const handleGithubTokenValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGithubTokenValue(event.target.value);
    };

    const handleGithubRepoUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        const url_regex = /(https:\/\/[^\/]*\.com)(.*)/;
        const url_match = event.target.value.match(url_regex);

        if (url_match && url_match[2]) {
            setGithubRepoUrl(url_match[2].startsWith('/') ? url_match[2].substring(1) : url_match[2]);
        } else {
            setGithubRepoUrl(event.target.value);
        }

        const name_regex = /https:\/\/[^\/]*\.com\/[^\/]*\/([^\/]*)\.git/;
        const name_match = event.target.value.match(name_regex);

        if (name_match && name_match[1]) {
            setGithubRepoName(name_match[1]);
            setGitHubRepoNameFlag(true)
        } else {
            setGithubRepoName('');
            setGitHubRepoNameFlag(false)
        }
    };

    const handleGithubRepoName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGithubRepoName(event.target.value);
    };

    const handlePLVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPLVersion(event.target.value);
    };

    const handleGithubRepoPath = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGithubRepoPath(event.target.value);
    };

    const handleProjectKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProjectKey(event.target.value.toUpperCase());
        setShowKeyError(projectKey.includes(' ') || projectKey.length > 5);
    };

    const handleMaxParallelChange = () => {
        setMaxParallelIsChecked(!maxParallelIsChecked);
        if (!maxParallelIsChecked) {
            dispatch(updateProjectRunSettings({project_id: currentProject.id, parallels: -1})).then(() => {
                currentProjectSettings.run_settings.parallels = -1;
                setAppContextProjectSettings(currentProjectSettings);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
    };

    const handleSetMaxParallel = (e: any) => {
        setRunParallel(e);
    };

    const handleRunBranchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRunBranch(event.target.value);
    };

    const handleBeforeRunChange = (event: boolean) => {
        setBeforeRunNotif(event);
    };

    const handleAfterRunChange = (event: boolean) => {
        setAfterRunNotif(event);
    };

    const handleGithubWarnings = (event: boolean) => {
        setGithubWarnings(event);
    };

    const handleCaseAssignmentChange = (event: boolean) => {
        setCaseAssignmentNotif(event);
    };

    const handleCaseUnassignmentChange = (event: boolean) => {
        setCaseUnassignmentNotif(event);
    };

    const handleSetRerun = (e: any) => {
        setRerun(e);
    };

    const handleVisibilitySwitchChange = (event: boolean) => {
        setProjectVisibility(event);
    };

    const handleRenameTitle = () => {
        const params = {
            project_id: currentProject.id,
            org_id: org_id,
            title: projectTitle
        }
        dispatch(updateProject(params))
            .then(() => {
                setAppContextProject({...currentProject, name: projectTitle})
                setProjectTitle(projectTitle)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    const handleRenameKey = () => {
        const params = {
            project_id: currentProject.id,
            org_id: org_id,
            key: projectKey
        }
        dispatch(updateProject(params))
            .then(() => {
                setAppContextProject({...currentProject, p_key: projectKey})
                setProjectKey(projectKey)
                navigate(`/${currentOrg.domain}/projects/${projectKey}/manage/settings`)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    const handleUpdatePostProjectRunSettings = () => {
        const params = {
            project_id: currentProject.id,
            parallels: maxParallelIsChecked || runParallel == 0 ? 20 : runParallel
        }
        dispatch(updateProjectRunSettings(params))
            .then(() => {
                currentProjectSettings.run_settings.parallels = runParallel;
                setAppContextProjectSettings(currentProjectSettings);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    const handleUpdatePostProjectRunBranchSettings = () => {
        const params = {
            project_id: currentProject.id,
            branch: runBranch
        }
        dispatch(updateProjectRunSettings(params))
            .then(() => {
                currentProjectSettings.run_settings.branch = runBranch;
                setAppContextProjectSettings(currentProjectSettings);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    const handleUpdatePostProjectRerunSettings = () => {
        const params = {
            project_id: currentProject.id,
            runs_number: rerun
        }
        dispatch(updateProjectRunSettings(params))
            .then(() => {
                currentProjectSettings.run_settings.runs_number = rerun;
                setAppContextProjectSettings(currentProjectSettings);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    const handleUpdateProjectType = async () => {
        const params = {
            project_id: currentProject.id,
            org_id: org_id,
            project_type_id: projectType
        }
        dispatch(updateProject(params)).then(() => {
            setProjectTypeName(projectType === 1 ? "Automation" : "Manual");
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })

    };

    const handleUpdateProjectOwner = async () => {
        const params = {
            project_id: currentProject.id,
            org_id: org_id,
            owner_id: projectOwner
        }
        dispatch(updateProjectOwner(params)).then(() => {
            setProjectOwner(projectOwner);
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const handleUpdateProjectVisibility = async () => {
        const params = {
            project_id: currentProject.id,
            org_id: org_id,
            project_visible: !projectVisibility
        }
        dispatch(updateProject(params))
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    const getProjectTypeOptions = async () => {
        dispatch(getProjectTypes(params)).then(settings => {
            setSavedProjectTypes(settings)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const getProjectOwnersOptions = async () => {
        dispatch(getProjectUsers(params)).then(users => {
            let data: User[] = []
            users.content.forEach(user => {
                data.push({id: user.id, name: user.name, lastname: user.lastname, email: user.email})
            });
            setSavedProjectOwners(data)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const handleUpdateProjectStatus = async () => {
        const params = {
            project_id: currentProject.id,
            org_id: org_id,
            project_state: !projectState
        }
        dispatch(updateProject(params)).then(() => {
            setProjectState(projectState === 1 ? 0 : 1);
            setProjectStateName(projectState === 1 ? "Archive" : "Active");
            setProjectStateStatus(projectState === 1 ? "Activated" : "Archived");
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const handleUpdateProjectTypeChange = async (event: number) => {
        setProjectType(event)
    };

    const handleUpdatePLChange = async (event: any) => {
        setCurrentPL(event)
    };

    const handleUpdateModuleTypeChange = async (event: string) => {
        setModuleType(event)
    };

    const handleUpdateProjectOwnerChange = async (event: number) => {
        setProjectOwner(event)
    };

    const constructProjectTypes = () => {
        return savedProjectTypes.map(project_type => {
            return <Option value={project_type.id} label={project_type.id}
                           style={project_type.id === projectType ? {color: colors.green.forest} : {color: 'black'}}>
                {project_type.title}
            </Option>
        });

    }

    const constructPL = () => {
        return savedPL.map(pl => {
            return <Option key={pl.id} value={pl.title} label={pl.title}
                           style={pl.title === currentPL?.title ? {color: colors.green.forest} : {color: 'black'}}>
                {pl.title}
            </Option>
        });

    }

    const constructModulesTypes = () => {
        return <><Option key={0}>
            Use Dependency Management file
        </Option>
            <Option key={1}>
                Build Custom Modules to Panda
            </Option></>

    }

    const constructProjectOwners = () => {
        return savedProjectOwners.map(project_owner => {
            return <Option value={project_owner.id} label={project_owner.lastname + " " + project_owner.name}
                           style={project_owner.id === projectOwner ? {color: colors.green.forest} : {color: 'black'}}>
                {project_owner.lastname + " " + project_owner.name}
            </Option>
        });

    }

    const handleUpdateBeforeRunNotif = async () => {
        dispatch(updateProjectNotificationSetting({id: currentProject.id, org_id: org_id, before_run: !beforeRunNotif}))
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    const handleUpdateAfterRunNotif = async () => {
        dispatch(updateProjectNotificationSetting({id: currentProject.id, org_id: org_id, after_run: !afterRunNotif}))
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    const handleUpdateProjectGithubIntegration = (extra_params:any) =>{
        dispatch(updateProjectGithubIntegration({
            project_id: currentProject.id,
            org_id: org_id,
            ...extra_params
        }))
        .catch((err) => {
            safeHandleErrorResponse(err)
        })
    }
    const handleUpdateGitHubWarnings = () => {
        handleUpdateProjectGithubIntegration({github_warnings: !githubWarnings})
    };

    const handleUpdateGitHubUser = () => {
        handleUpdateProjectGithubIntegration({integration_user: githubUser})
    };

    const handleUpdateGitHubToken = () => {
        handleUpdateProjectGithubIntegration({token_value: githubTokenValue, token_name: githubTokenName})
    };

    const handleUpdateGitHubRepoUrl = () => {
        handleUpdateProjectGithubIntegration({repo_url: githubRepoUrl})
    };

    const handleUpdateGitHubRepoName = () => {
        handleUpdateProjectGithubIntegration({repo_name: githubRepoName})

    };

    const handleUpdateGitHubRepoPaths = () => {
        handleUpdateProjectGithubIntegration({repo_path: githubRepoPath})
    };

    const handleUpdatePL = () => {
        dispatch(updateProjectProgrammingLanguages({
            project_id: currentProject.id,
            org_id: org_id,
            language_id: savedPL.find(item => item.title === currentPL?.title)?.id as number,
            version: plVersion as string
        })).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const handleUpdateCaseAssignmentNotif = () => {
        dispatch(updateProjectNotificationSetting({
            id: currentProject.id,
            org_id: org_id,
            case_assignment: !caseAssignmentNotif
        })).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const handleUpdateCaseUnassignmentNotif = () => {
        dispatch(updateProjectNotificationSetting({
            id: currentProject.id,
            org_id: org_id,
            case_unassignment: !caseUnassignmentNotif
        })).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const setting_sections: setting_sections = [
        {
            attr_id: 'ps-general', title: 'General', settings: [
                {
                    title: 'Project Title', description: 'Change the Title of Project.',
                    value:
                        <Space.Compact>
                            <Input placeholder='Project Title'
                                   value={projectTitle}
                                   onChange={handleProjectTitleChange}
                                   style={{width: 200}}/>
                            <Button type='default'
                                    onClick={handleRenameTitle}
                                    disabled={projectTitle === currentProject.name}>
                                Rename
                            </Button>
                        </Space.Compact>
                }, {
                    title: 'Project Image', description: '300x200',
                    value: <UploaderC
                        max_items={1}
                        url={'/icons/default_project.png'}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"/>
                }
            ]
        },
        {
            attr_id: 'ps-users', title: 'Users', settings: [
                {
                    title: 'User Dashboard', description: `Total ${addProjectUsers.length} members.`,
                    value: <ButtonC
                        onClick={() => navigate(`/${currentOrg.domain}/projects/${currentProject.p_key}/manage/users`)}
                        text='View'/>
                }
            ]
        },
        {
            attr_id: 'ps-run-conf', title: 'Run Configuration', settings: [], children: [{
                attr_id: 'ps-run-default-setting',
                title: 'Default Settings',
                description: 'Default configuration before you create a new run.',
                settings: [{
                    title: 'Default Github Branch',
                    description: 'Set Default Github Branch.',
                    value:
                        <Space.Compact>
                            <Input placeholder='Default Github Branch'
                                   onChange={handleRunBranchChange}
                                   value={runBranch}
                                   style={{width: 200}}/>
                            <Button type='default'
                                    onClick={handleUpdatePostProjectRunBranchSettings}>
                                Set Default</Button>
                        </Space.Compact>
                }, {
                    title: 'Default Reruns',
                    description: 'Set Default Reruns.',
                    value:
                        <Space.Compact>
                            <InputNumber min={0}
                                         keyboard
                                         style={{width: 60}}
                                         onChange={(e) => handleSetRerun(e)}
                                         value={rerun}
                                         defaultValue={0}/>
                            <Button type='default'
                                    onClick={handleUpdatePostProjectRerunSettings}>
                                Set Default</Button>
                        </Space.Compact>
                }, {
                    title: 'Default Parallel',
                    description: 'Set Default Parallel.',
                    value:
                        <Space.Compact>
                            <CheckboxC checked={maxParallelIsChecked} style={{marginLeft: 10, marginTop: 5}}
                                       onChange={handleMaxParallelChange}
                                       label='Max Parallel'/>
                            <InputNumber min={1}
                                         keyboard
                                         onChange={(e) => handleSetMaxParallel(e)}
                                         value={runParallel}
                                         disabled={maxParallelIsChecked}/>
                            <Button type='default'
                                    disabled={maxParallelIsChecked}
                                    onClick={handleUpdatePostProjectRunSettings}>
                                Set Default</Button>
                        </Space.Compact>
                }, {
                    title: 'Default Browsers',
                    description: 'Set Default Parallel.',
                    value:
                        <Select
                            mode="multiple"
                            style={{marginLeft: 10, width: 130}}
                            placeholder="Select Browsers"
                            optionLabelProp="label"
                            value={['Chrome']}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                            }>
                            <Option value={'Chrome'} label={'Chrome'}>
                                Chrome
                            </Option>
                            <Option value={'Chrome'} label={'Chrome'}>
                                FireFox
                            </Option>
                        </Select>
                }]
            }, {
                attr_id: 'ps-run-arguments', title: 'Run Arguments', settings: [{
                    title: 'Arguments',
                    description: 'These Arguments will pass to your script.',
                    value:
                        <ButtonC
                            onClick={() => navigate(`/${currentOrg.domain}/projects/${currentProject.p_key}/manage/args_scripts`)}
                            text='View'/>
                }]
            },
                {
                    attr_id: 'ps-run-scripts', title: 'Run Scripts', settings: [{
                        title: 'Scripts',
                        description: 'Set up scripts before and after the run completion.',
                        value:
                            <ButtonC
                                onClick={() => navigate(`/${currentOrg.domain}/projects/${currentProject.p_key}/manage/args_scripts`)}
                                text='View'/>
                    }]
                }]
        },
        {
            attr_id: 'ps-notifications', title: 'Notifications', settings: [], children: [
                {
                    attr_id: 'ps-run-notifications',
                    title: 'Run',
                    description: 'Project_members will be notified during Run activities.',
                    settings: [{
                        title: 'Before Run',
                        description: 'Project_members will be notified before the run starts.',
                        value: <Switch onChange={handleBeforeRunChange} onClick={handleUpdateBeforeRunNotif}
                                       checked={beforeRunNotif}/>
                    }, {
                        title: 'After Run', description: 'Project_members will be notified when the run is completed.',
                        value: <Switch onChange={handleAfterRunChange} onClick={handleUpdateAfterRunNotif}
                                       checked={afterRunNotif}/>
                    }]

                },
                {
                    attr_id: 'ps-case-notifications',
                    title: 'Case',
                    description: 'Project_members will be notified during Case activities.',
                    settings: [
                        {
                            title: 'Case Assigment',
                            description: 'The new Test Case Owner will be notified on Test Case transfer ownership.',
                            value: <Switch onChange={handleCaseAssignmentChange}
                                           onClick={handleUpdateCaseAssignmentNotif} checked={caseAssignmentNotif}/>
                        }, {
                            title: 'Case Unassigment',
                            description: 'The previous Test Case owner will be notified on losing ownership of a Test Case.',
                            value: <Switch onChange={handleCaseUnassignmentChange}
                                           onClick={handleUpdateCaseUnassignmentNotif} checked={caseUnassignmentNotif}/>
                        }]

                }
            ]
        },
        {
            attr_id: 'ps-integrations', title: 'Integrations', settings: [], children: [{
                attr_id: 'ps-github', title: 'Github', description: 'Set up your GitHub Integration.', settings:
                    [{
                        title: 'Github Integration User',
                        description: 'Your Default Integration User email.',
                        value: <><Input
                            style={{width: 200}}
                            placeholder='Set user email'
                            value={githubUser}
                            onChange={handleGithubUser}/>
                            <Button
                                type='default'
                                disabled={githubUser === undefined || githubUser === null || githubUser === ''}
                                onClick={handleUpdateGitHubUser}>Set
                            </Button>
                        </>
                    }, {
                        title: 'Github Token',
                        description: 'Set your Github Token name and value.',
                        value: <div>
                            <Input
                                style={{width: 200}}
                                placeholder='Set token name'
                                value={githubTokenName}
                                onChange={handleGithubTokenName}/><br/>
                            <Input
                                style={{width: 200}}
                                placeholder='Set token value'
                                value={githubTokenValue}
                                onChange={handleGithubTokenValue}/><br/>
                            <Button
                                type='default'
                                disabled={githubTokenName === undefined || githubTokenName === null || githubTokenName === '' || githubTokenValue === undefined || githubTokenValue === null || githubTokenValue === ''}
                                onClick={handleUpdateGitHubToken}>Set</Button>
                        </div>
                    }, {
                        title: 'Github Repo URL',
                        description: 'Your default Github Repo URL.',
                        value: <div style={{marginTop: 20}}>
                            <Input
                                style={{width: 200}}
                                placeholder='Set github repo url'
                                value={githubRepoUrl}
                                onChange={handleGithubRepoUrl}/>
                            <Button
                                type='default'
                                disabled={githubRepoUrl === undefined || githubRepoUrl === null || githubRepoUrl === ''}
                                onClick={handleUpdateGitHubRepoUrl}>Set
                            </Button>
                        </div>
                    }, {
                        title: 'Github Repo Name',
                        description: 'Your default Github Repo Name.',
                        value: <div style={{marginTop: 20}}>
                            <Input
                                style={{width: 200}}
                                placeholder='Set github repo name'
                                value={githubRepoName}
                                onChange={(githubRepoName === undefined || githubRepoName === null || githubRepoUrl === undefined || githubRepoUrl === null || githubRepoUrl === '' || !gitHubRepoNameFlag) ?
                                    handleGithubRepoName : () => {
                                    }}/>
                            <Button
                                type='default'
                                disabled={githubRepoName === undefined || githubRepoName === null || githubRepoName === ''}
                                onClick={handleUpdateGitHubRepoName}>Set
                            </Button>
                        </div>
                    }, {
                        title: 'Github Repo Paths',
                        description: 'These fields can be open.',
                        value: <div style={{marginTop: 20}}>
                            <Input
                                style={{width: 200}}
                                placeholder='Set github repo project path'
                                value={githubRepoPath}
                                onChange={handleGithubRepoPath}/>
                            <Button
                                type='default'
                                disabled={githubRepoPath === undefined || githubRepoPath === null || githubRepoPath === ''}
                                onClick={handleUpdateGitHubRepoPaths}>Set
                            </Button>
                        </div>
                    }, {
                        title: 'Github Warnings', description: 'GitHub warning logs will be sent.',
                        value: <Switch
                            onChange={handleGithubWarnings}
                            onClick={handleUpdateGitHubWarnings}
                            checked={githubWarnings}
                            style={{marginTop: 20}}/>
                    }
                    ]
            },
                {
                    attr_id: 'ps-slack', title: 'Slack', description: 'Set up your Slack Integrations.', settings: [{
                        title: <Slacks/>,
                        description: '',
                        value: ''
                    }
                    ]
                },
                // {attr_id: 'ps-jenkins', title: 'Jenkins', description: 'Set up your Jenkins Integration.',settings: [{
                //     title: 'Default Branch',
                //     description: 'Your Default Project Branch.', value: <><Input style={{width: 200}}/><Button type='default'>Set</Button></>
                // },{
                //     title: 'Default Branch',
                //     description: 'Your Default Project Branch.', value: <><Input style={{width: 200}}/><Button type='default'>Set</Button></>
                // },{
                //     title: 'Default Branch',
                //     description: 'Your Default Project Branch.', value: <><Input style={{width: 200}}/><Button type='default'>Set</Button></>
                // }]
                // },
                // {
                //     attr_id: 'ps-jira', title: 'Jira', description: 'Set up your Jira Integration.', settings: [{
                //         title: 'Default Branch',
                //         description: 'Your Default Project Branch.',
                //         value: <><Input style={{width: 200}}/><Button type='default'>Set</Button></>
                //     }, {
                //         title: 'Default Branch',
                //         description: 'Your Default Project Branch.',
                //         value: <><Input style={{width: 200}}/><Button type='default'>Set</Button></>
                //     }, {
                //         title: 'Default Branch',
                //         description: 'Your Default Project Branch.',
                //         value: <><Input style={{width: 200}}/><Button type='default'>Set</Button></>
                //     }
                //     ]
                // }
            ]
        },
        {
            attr_id: 'ps-configuration', title: 'Configuration', settings: [], children: [{
                attr_id: 'ps-language', title: 'Programming Language', description: 'Configure your SDK.', settings: [{
                    title: 'Programming Language',
                    description: 'Set Programming Language.',
                    value: project_sdk()
                }, {
                    title: 'Modules',
                    description: 'Version of {Language Selected}.',
                    value: <><Select onChange={handleUpdateModuleTypeChange} placeholder="Choose Module Setup Type"
                                     defaultValue={moduleType} style={{width: 300}}>
                        {constructModulesTypes()}
                    </Select>
                        {/* <Button type='default'>Set</Button> */}
                        {moduleType === '0' &&
                        <div><br/><span>You can find how to develop the Dependency Management file <a target='_blank' href=''>here</a>.</span>
                        </div>}

                        {moduleType === '1' &&
                        <div><br/>{custom_modules()}</div>}
                    </>
                }
                ]
            },
                // {attr_id: 'ps-modules', title: 'Modules', settings: [{
                //     title: 'Programming Language',
                //     description: 'Set Programming Language.',
                //     value: <><Input style={{width: 200}}/><Button type='default'>Set</Button></>
                // },{
                //     title: 'Version',
                //     description: 'Version of {Language Selected}.',
                //     value: <><Input style={{width: 200}}/><Button type='default'>Set</Button></>
                // }
                // ]},
                {
                    attr_id: 'ps-ui-framework', title: 'UI Framework', settings: [{
                        title: 'UI Framework',
                        description: 'Pick a UI Framework.',
                        value: <>Selenium</>
                    }, {
                        title: 'version',
                        description: 'Select a version.',
                        value: <>3.141.59</>
                    }, {
                        title: 'Default Browser',
                        description: 'You can configure this per run on your code.',
                        value: <>Chrome - 87.0.4280.88</>
                    }, {
                        title: 'Custom Hub',
                        description: 'Set your own hub.',
                        value: <>http://selenium-qa-hub:4444/grid/register</>
                    },
                        // {
                        //     title: 'Selenium Version',
                        //     description: 'Set Programming Language.',
                        //     value: <></>
                        // },{
                        //     title: 'Selenium Version',
                        //     description: 'Set Programming Language.',
                        //     value: <></>
                        // },{
                        //     title: 'Version',
                        //     description: 'Version of {Language Selected}.',
                        //     value: <></>
                        // }
                    ]
                }
            ]
        },
        {
            attr_id: 'ps-actions', title: 'Actions', settings: [
                {
                    title: 'Project Key', description: 'No spaces are allowed. Maximum allowed characters: 5',
                    value:
                        <Space.Compact>
                            <Input placeholder='Project Key'
                                   value={projectKey}
                                   onChange={handleProjectKeyChange}
                                   style={{width: 200, borderColor: showKeyError ? '#F6C324' : ''}}/>
                            <Button type='default'
                                    onClick={handleRenameKey}
                                    disabled={projectKey === currentProject.p_key || showKeyError || projectKeys.includes(projectKey)}>
                                Rename</Button>
                            {showKeyError &&
                            <span style={{color: '#F6C324', marginLeft: 10, marginTop: 5}}>Please enter a value without spaces. Maximum allowed values: 5</span>}
                            {projectKeys.includes(projectKey) && projectKey !== currentProject.p_key &&
                            <span style={{color: '#F6C324', marginLeft: 10, marginTop: 5}}>The specific key is already in use. Please choose another one.</span>}
                        </Space.Compact>
                },
                {
                    title: 'Visibility',
                    description: 'As Visible, users can join to the project, otherwise they can only be added by Organization Admins or Project Owners.',
                    value: <Switch onChange={handleVisibilitySwitchChange} onClick={handleUpdateProjectVisibility}
                                   checked={projectVisibility}/>
                }, {
                    title: 'Storage', description: 'Clear Storage. This Action can not be undone.',
                    value: <Button danger type='default'>clear</Button>
                }, {
                    title: 'Provide the desired Project Owner',
                    description: 'Change The owner of the Project. The new owner must be a member in this project. Make sure you are aware of what your doing.',
                    value: <><Select onChange={handleUpdateProjectOwnerChange} placeholder="Choose Project Owner"
                                     defaultValue={projectOwnerName} onClick={getProjectOwnersOptions}>
                        {constructProjectOwners()}
                    </Select>
                        <Button
                            style={{marginLeft: 10}}
                            size='small'
                            shape='circle'
                            onClick={handleUpdateProjectOwner}
                            icon={<CheckCircleOutlined
                                style={{color: colors.green.forest}}/>}/></>
                }, {
                    title: 'Current Project Type: ' + projectTypeName,
                    description: 'Change The Type of the Project. Make sure you are aware of what your doing.',
                    value: <><Select onChange={handleUpdateProjectTypeChange} placeholder="Choose Project Type"
                                     defaultValue={projectTypeName} onClick={getProjectTypeOptions}>
                        {constructProjectTypes()}
                    </Select>
                        <Button
                            style={{marginLeft: 10}}
                            size='small'
                            shape='circle'
                            onClick={handleUpdateProjectType}
                            icon={<CheckCircleOutlined
                                style={{color: colors.green.forest}}/>}/></>
                }, {
                    title: projectStateStatus, description: 'Look here on how to ' + projectStateName + '.',
                    value: <Button danger onClick={handleUpdateProjectStatus}>{projectStateName}</Button>
                }, {
                    title: 'Delete', description: 'This can not be undone.',
                    value: <Popconfirm placement="top"
                                       title={<p>Are you sure you want to Delete Project <b>{currentProject.name}</b>
                                           <br/>
                                           This action is not reversible. You can archive it instead and use it in the
                                           future.</p>}
                                       onConfirm={handleDelete}
                                       okText='Delete'

                    > <Button danger>Delete</Button> </Popconfirm>
                }
            ]
        }
    ]


    return (
        <Page title="Settings">
            <PageHeader title={`Project Settings - ${projectTitle}`}/>
            <PageContent>
                <Settings setting_sections={setting_sections}/>
                <ModuleModal visible={visible}
                             setVisible={setVisible}
                             modulePage={rows}
                             setModulePage={setRows}
                             selectedModule={selectedModule}
                             setSelectedModule={setSelectedModule}
                />
                <FloatButton.BackTop/>
            </PageContent>
        </Page>
    )
}

export default ProjectSettings;
