import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {DataNode} from 'antd/lib/tree';
import {getSections} from '../../redux/actions/section_actions';
import {AppDispatch} from '../../redux/store';
import {useDispatch} from 'react-redux';
import {useAppContext} from "../../AppContext";
import TestSuite from "../test_suite/test_suite";
import RowC from "../../components/row";
import {Button, Card, Col, Input, Select, Space, Tag} from "antd";
import {colors} from "../../assets/data/colors";
import {CloseOutlined, SyncOutlined} from "@ant-design/icons";
import TestReviewTable from "./test_review_table";
import {getRunCases, getRunComponentCases, getRunInfo, getRunReviewers, getRunTotalCasesStatuses, updateRunCaseStatus} from "../../redux/actions/run_actions";
import {PageResource} from "../../models/dtos/page_resource";
import {createEmptyPage} from "../../services/utils/PageResourceUtils";
import {Column} from '@ant-design/plots';
import {RunCases} from "../../models/entities/test_reviews";
import {TestReview} from '../../models/entities/test_reviews';
import {empty_run} from "../../services/EmptyEntities/EmptyRun";
import {run_statuses} from "../../assets/data/run";
import {MemoPie} from '../../components/chart/pie';
import {Reviewer} from "../../models/entities/test_case_info";
import type { SelectProps } from 'antd';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {PageDivider} from "../../components/page_structure/page_divider";

const {Option} = Select;
const { Search } = Input;

type TagRender = SelectProps['tagRender'];

interface ColumnData {
    type: string,
    value: number
}

const MemoizedColumn = React.memo(({ myStatusData }: { myStatusData: ColumnData[] }) => {
    return <Column
        style={{maxHeight: 200}}
        {...{
            data: myStatusData,
            xField: 'type',
            yField: 'value',
            localRefresh: false,
            color: ({type}) => {
                if (type === 'Untested') return colors.gray.shadow
                if (type === 'Unresolved') return colors.orange.default
                if (type === 'Passed') return colors.green.forest
                if (type === 'Failed') return colors.red.vermilion
                return colors.blue.dark
            },
            label: {
                position: 'top',
                style: {
                    fill: '#000',
                    opacity: 0.7,
                },
            },
            xAxis: {
                label: {
                    autoHide: false,
                    autoRotate: false,
                },
            }
        }}/>;
});


function RunReviewResults(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const location = useLocation()
    const { state } = location;
    let default_reviewers:Reviewer[] = []
    let default_reviewInfo:TestReview = empty_run
    const hasState = state && state.run

    if(hasState){
        default_reviewers = state.run.reviewers
        default_reviewInfo = state.run
    }
    const {currentOrg, currentProject, loggedInUser} = useAppContext();

    let {run_id} = useParams();

    const [viewState, setViewState] = useState<'table' | 'structured'>('table')
    const [selectedStatuses, setSelectedStatuses] = useState<any[]>([0, 2, 3])

    const [reviewInfo, setReviewInfo] = useState<TestReview>(default_reviewInfo)
    const [runState, setRunState] = useState<string>('')

    // assignees state
    const [selectedAssignees, setSelectedAssignees] = useState<number[]>([loggedInUser.id])
    const [reviewers, setReviewers] = useState<Reviewer[]>(default_reviewers)
    const [searchedReviewers, setSearchedReviewers] = useState<Reviewer[]>([])

    const [tablePage, setTablePage] = useState<PageResource<RunCases>>(createEmptyPage())
    const [treeData, setTreeData] = useState<DataNode[]>([])

    const [search, setSearch] = useState<string>('')
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

    // table options
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableSize, setTableSize] = useState<number>(15)
    const [tableCurrentPage, setTableCurrentPage] = useState<number>(1)

    const [componentData, setComponentData] = useState<{type: string, value: number}[]>([])
    const [myStatusData, setMyStatusData] = useState<ColumnData[]>([])
    const [runProgressData, setRunProgress] = useState<ColumnData[]>([])

    let org_id = currentOrg.id

    const fetchReviewers = () => {
        return dispatch(getRunReviewers(run_id !== undefined ? parseInt(run_id) : -1, debouncedSearchTerm))
    }

    const fetchData = () => {
        if (viewState === 'table') {
            setTableLoading(true)
            dispatch(getRunCases({
                run_id: run_id !== undefined ? parseInt(run_id) : -1,
                limit: tableSize,
                offset: (tableCurrentPage - 1) * tableSize,
                statuses: selectedStatuses,
                assignees: selectedAssignees,
                search
            })).then(pageFound => {
                setTablePage(pageFound)
                setTableLoading(false)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        } else {
            dispatch(getSections(org_id, currentProject.id)).then(sectionsPage => {
                setTreeData(sectionsPage)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
    }

    useEffect(() => {
        // Save state information to sessionStorage
        sessionStorage.setItem('myState', JSON.stringify(state));

        // Attach a listener to the beforeunload event
        const beforeUnloadHandler = () => {
            // Clear the state information from sessionStorage
            sessionStorage.removeItem('myState');
        };

        window.addEventListener('beforeunload', beforeUnloadHandler);

        // Cleanup function
        return () => {
            // Remove the beforeunload event listener when the component is unmounted
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        };
    }, [state])

    useEffect(() =>{
        // get  defaults from appContext
        dispatch(getRunTotalCasesStatuses(reviewInfo.id))
            .then((found)=> {
                let data: {type: string, value: number}[] = []
                found.content.forEach(item => {
                    data.push({type: item.type, value: parseFloat((item.value / reviewInfo.progress.total * 100).toFixed(0))})
                });
                setRunProgress(data)
                setMyStatusData(found.content)
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
    }, [dispatch, tablePage])

    useEffect(() => {
        // Use a timeout to delay the search request
        const timeoutId = setTimeout(() => {
            if(searchTerm!==''){
                setDebouncedSearchTerm(searchTerm);
            }else{
                setSearchedReviewers([])
            }
        }, 500);

        // Clear the timeout on every input change
        return () => clearTimeout(timeoutId);
    }, [searchTerm]);

    useEffect(() => {
        // Perform your search logic here with debouncedSearchTerm
        // This will run after the 700ms delay when the user stops typing
        fetchReviewers()
            .then((dataFound)=>{
                setSearchedReviewers(dataFound)
            })

    }, [debouncedSearchTerm]);

    useEffect(()=>{
        if(default_reviewers.length === 0){
            fetchReviewers()
                .then((dataFound)=>{
                    setReviewers(dataFound)
                })
        }

        dispatch(getRunComponentCases({org_id, run_id: reviewInfo.id}))
            .then((resPieStats)=>{
                setComponentData(resPieStats.components)
            }).catch((err) => {
            safeHandleErrorResponse(err)
        })

    }, [dispatch])


    useEffect(() => {
        const review_info: TestReview = state ? state.run : null

        if (!review_info) {
            // fetch data
        }
        fetchData();
    }, [dispatch, viewState, selectedStatuses, selectedAssignees, tableCurrentPage, tableSize])

    useEffect(() => {
        const fetchData = () => {
            dispatch(getRunInfo(org_id, run_id)).then(found => {
                if (found.content)
                {setRunState(found.content.state)}
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch, tablePage])

    const handleChangePagination = (current:number, size:number) => {
        setTableCurrentPage(current)
        setTableSize(size)
    }

    const handleStatusChange = (event: string[]) => {
        setSelectedStatuses(event);
    };
    const handleStatus=(ids:number[], status:number, resolved_comment:string)=>{
        const data = {
            status,
            resolved_by: loggedInUser.id,
            resolved_comment
        }
        dispatch(updateRunCaseStatus(ids, data))
            .then(()=>{
                const temp_table = {...tablePage}
                for (let i = 0; i < temp_table.content.length; i++) {
                    // Check if the id is in array2
                    if (ids.includes(temp_table.content[i].row_id)) {
                        // Update the status to 2
                        temp_table.content[i].run.status = status;
                    }
                }
                if(selectedStatuses.length>0){
                    temp_table.content = temp_table.content.filter(item => selectedStatuses.includes(item.run.status));
                }
                setTablePage(temp_table)

                let temp_status = [...myStatusData]
                temp_status[0].value ++
                setMyStatusData(temp_status)
            }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }

    const handleReset = () => {
        setSelectedStatuses([0, 2, 3])
        setSelectedAssignees([loggedInUser.id])
        setSearch('')
    }

    const tagRender: TagRender = (props) => {
        const { label, value, closable, onClose } = props;

        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                color={run_statuses[value].color}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                closeIcon={<CloseOutlined/>}
            >
                {label}
            </Tag>
        );
    };

    const getColorForStatus = (type: string) => {
        switch(type) {
            case "Passed":
                return colors.green.forest;
            case "Failed":
                return colors.red.vermilion;
            case "Unresolved":
                return colors.orange.default;
            case "Untested":
                return colors.gray.shadow;
            default:
                return colors.yellow.default; // fallback color
        }
    };

    const processedData = runProgressData.map(data => ({
        ...data,
        color: getColorForStatus(data.type)
    }));

    return <RowC>
        <Col flex='auto'>
            <RowC gutter={[16,16]} style={{marginTop: 20, marginBottom: 20}}>
                <Col>
                    <Select
                        mode="multiple"
                        style={{marginLeft: 10, width: 225}}
                        placeholder="Select Assignees"
                        value={selectedAssignees}
                        onDropdownVisibleChange={(open)=>{if(!open)setSearchTerm('')}}
                        onChange={(value) => setSelectedAssignees(value)}
                        filterOption={false}
                        showSearch={true}
                        maxTagCount={5}
                        onSearch={setSearchTerm}
                        optionLabelProp="label"
                    >
                        {
                            searchTerm.length>0?
                                searchedReviewers.map(reviewer=>{
                                    return <Option key={`s-${reviewer.assignee}`} value={reviewer.assignee} label={reviewer.initials}>
                                        {reviewer.fullname}
                                    </Option>
                                })
                                :
                                <>
                                    {
                                        reviewers.map(reviewer=>{
                                            return <Option key={`m-${reviewer.assignee}`} value={reviewer.assignee} label={reviewer.initials}>
                                                {reviewer.fullname}
                                            </Option>
                                        })
                                    }
                                </>
                        }

                    </Select>
                </Col>
                <Col>
                    <Select
                        mode="multiple"
                        style={{marginLeft: 10, width: 225}}
                        placeholder="Select Status"
                        value={selectedStatuses}
                        onChange={handleStatusChange}
                        tagRender={tagRender}
                        optionLabelProp="label"
                    >
                        <Option value={0} label="F">
                            <Space>Failed</Space>
                        </Option>
                        <Option value={1} label="P">
                            <Space>Passed</Space>
                        </Option>
                        <Option value={2} label="UT">
                            <Space>Untested</Space>
                        </Option>
                        <Option value={3} label="UR">
                            <Space>Unresolved</Space>
                        </Option>
                    </Select>
                </Col>
                <Col>
                    <Search
                        style={{marginLeft: 10, width: 250}}
                        placeholder="Search for case"
                        onSearch={fetchData}
                        value={search}
                        onChange={(value)=>setSearch(value.target.value)}
                    />
                </Col>
                <Col>
                    <Button
                        icon={<SyncOutlined/>}
                        loading={false}
                        onClick={handleReset}>Reset</Button>
                </Col>
                <PageDivider/>
            </RowC>
            {/* <Segmented
                style={{marginTop: 15}}
                options={[{
                    label: 'Table',
                    value: 'table',
                    icon: <TableOutlined/>,
                }, {
                    label: 'Structured',
                    value: 'structured',
                    icon: <img style={{width: 16, marginTop: 4}} src="/icons/folder_structure.png"
                               alt="Folder Structured"/>
                }]}
                onChange={(value: any) => setViewState(value)}
            /> */}
            {
                viewState === 'table' ?
                    <TestReviewTable
                        tablePage={tablePage}
                        setTablePage={setTablePage}
                        handleStatus={handleStatus}
                        loading={tableLoading}
                        paginationProps={{
                            current: tableCurrentPage,
                            pageSize: tableSize,
                            onChange: handleChangePagination
                        }}

                    />
                    : <TestSuite/>
            }
        </Col>
        <Col flex='330px'
             style={{backgroundColor: colors.gray.background, marginTop: -25, marginRight: -40}}>
            <Card style={{marginLeft: 15, marginTop: 15, width: 300}} title={<>
                <div style={{float: 'left'}}>{reviewInfo.info.name}</div>
                <div style={{float: 'right'}}>P</div>
            </>}>
                <RowC><Col flex={'80px'}>Branch</Col><Col flex={'auto'}>{reviewInfo.branch}</Col></RowC>
                <RowC><Col flex={'80px'}>State</Col><Col flex={'auto'}>{runState}</Col></RowC>
                <RowC><Col flex={'80px'}>Created At</Col><Col
                    flex={'auto'}>{reviewInfo.info.created_at}</Col></RowC>
            </Card>
            <Card style={{marginLeft: 15, marginTop: 15, width: 300}} title='My Status'>
                <MemoizedColumn myStatusData={myStatusData} />
            </Card>
            <RowC style={{marginLeft: 15, marginTop: 15, width: 300}}>
                <Card style={{width: 300}} title={'Run Progress'}>
                    <MemoPie
                        style={{height: 250, marginTop: -35, marginLeft: -20}}
                        innerRadius={0}
                        suffix={'%'}
                        colors={processedData.map(d => d.color)}
                        data={runProgressData}
                    />
                </Card>
            </RowC>
            <RowC style={{marginLeft: 15, marginTop: 15, width: 300, marginBottom: 15}}>
                <Card style={{width: 300}} title='Components'>
                    <MemoPie data={componentData} style={{height: 250, marginTop: -35, marginLeft: -15}} />
                </Card>
            </RowC>
        </Col>
    </RowC>

}

export default RunReviewResults;