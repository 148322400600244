import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {getLandingProject} from '../../redux/actions/project_actions';
import {AppDispatch} from '../../redux/store';
import {useAppContext} from '../../AppContext';
import {Organization} from '../../models/entities/organization';
import {Project} from '../../models/entities/project';
import { get_item_from_lcs } from '../../assets/local_storage/lcs';
import AuthLayout from "../../layouts/auth_layout";
import SkeletonLayout from "../../components/skeleton";
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";


function CalculateLandingPage(): JSX.Element {
    const {
        loggedInUser, currentOrg, currentProject, userToken,
        setAppContextOrg, setAppContextProject, setUserToken, resetApp
    } = useAppContext();

    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        dispatch(getLandingProject(loggedInUser.id))
            .then(found_page => {
                const org_found: Organization = {
                    id: found_page.org_id,
                    name: found_page.org_name,
                    domain: found_page.org_domain,
                    icon: found_page.org_icon
                }

                setAppContextOrg(org_found)

                const project_found: Project = {
                    id: found_page.project_id,
                    p_key: found_page.project_key,
                    name: found_page.project_name,
                    org_id: found_page.org_id,
                    test_cases: 0,
                }

                setAppContextProject(project_found)
                setUserToken(get_item_from_lcs('user_token'))
                setLoading(false)
            })
            .catch(err => {
                resetApp()
                safeHandleErrorResponse(err)
            })

    }, [dispatch]);

    return !!userToken?
        loading?
            <SkeletonLayout/>:
            !!currentOrg.id ?
                !!currentProject.id?
                    <Navigate to={`/${currentOrg.domain}/projects/${currentProject.p_key}/overview`}/>
                    :<Navigate to={`/${currentOrg.domain}/overview`}/>
                : <Navigate to='/create_org'/>

        : <AuthLayout page_type='underConstruction'><p/></AuthLayout>
}

export default CalculateLandingPage;

