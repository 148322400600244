import { User, OrgUsersInfo, UserPerms } from "../../models/entities/user";

export const empty_user:User = {
    id: 0,
    name: "",
    lastname: "",
    email: "",
    password: ""
}

export const empty_org_user_info:OrgUsersInfo = {
    org_id: 1,
    user_info: empty_user,
    role: 1,  // admin
    color: '#fff',
    user_teams_info: [],
    user_jobs_info: []
}

export const empty_user_perms:UserPerms = {
    organization: {
        manage_org_settings: false,
        manage_org_users: false,
        manage_org_permissions: false,
        manage_org_jobs: false,
        check_org_logs: false
    },
    project: {
        create_templates: false,
        manage_projects: false,
        manage_runs: false
    },
    team: {
        manage_teams: false,
    }
}
