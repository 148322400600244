import {LoadTemplatePage} from "../../models/entities/template";
import {PageResource} from "../../models/dtos/page_resource";


export const dummyTemplates: PageResource<LoadTemplatePage> = {
    content: [{
        id: 'asdsadsadasdas',
        projectId: 1,
        name: 'New Run Projects',
        projectType: 1,
        creatorId: 1,
        options: {
            settings: {
                title: "New Run Projects",
                githubBranch: "panos/something",
                browsers: [],
                total_runs: 2,
                maxParallel: 1,
                runSchedule: {
                    option: 'Scheduled',
                    start: "2024-10-16 00:00:00",
                    recurring: {
                        every: 'Day',
                        end: "2024-10-30 00:00:00"
                    }
                }
            },
            args: []
        }
    }, {
        id: 'asdsadsadasdas2',
        projectId: 1,
        name: 'New Run Org',
        projectType: 1,
        creatorId: 1,
        options: {
            settings: {
                title: "New Run Org",
                githubBranch: "something",
                browsers: [],
                total_runs: 4,
                maxParallel: 2,
                runSchedule: {
                    option: 'Scheduled',
                    start: "2024-10-20 00:00:00",
                    recurring: {
                        every: 'Week',
                        end: "2024-11-20 00:00:00"
                    }
                }
            },
            notifications: {
                system: [],
                slack: []
            }
        }
    }, {
        id: 'asdsadsadasdas3',
        projectId: 1,
        name: 'New Run Teams',
        projectType: 1,
        creatorId: 1,
        options: {
            settings: {
                title: "New Run Teams",
                githubBranch: "panos",
                browsers: [],
                total_runs: 1,
                maxParallel: 3,
                runSchedule: {
                    option: 'Scheduled',
                    start: "2025-2-22 00:00:00",
                    recurring: {
                        every: 'Day',
                        end: "2025-3-24 00:00:00"
                    }
                }
            },
            selection: {
                cases: [],
                sections: [],
                components: []
            }
        }
    }],
    first: true,
    last: false,
    number: 10,
    numberOfElements: 10,
    limit: 10,
    totalElements: 30,
    totalPages: 3
}