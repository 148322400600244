import React from "react";
import {
    BarChartOutlined, CalendarOutlined,
    PlayCircleOutlined, DashboardFilled, SettingOutlined, TagFilled,
    CheckSquareFilled, FolderOpenOutlined, RadarChartOutlined, HddOutlined,
    HomeOutlined, TeamOutlined, UserOutlined, ToolOutlined,
    KeyOutlined, ProjectOutlined, BellOutlined, BankOutlined
} from "@ant-design/icons";
import { UserPerms } from "../../../models/entities/user";

/*
    fetch Projects
    fetch user role
*/

export type sideMenuItems = {
    label: string,
    href?: string,
    icon: JSX.Element,
    has_access: boolean,
    children?: {
        label: string,
        icon?: JSX.Element,
        href: string,
        has_access: boolean
    }[]
}

export const projectMenuItems = (userPerms: UserPerms): sideMenuItems[] => {
    const menuItems: sideMenuItems[] = [
    {
        label: 'Overview',
        href: 'overview',
        icon: <DashboardFilled/>,
        has_access: true
    },
    {
        label: 'Test Review', 
        href: 'test-reviews',
        icon: <CheckSquareFilled />,
        has_access: true
    },
    {
        label: 'Run',
        href: 'run',
        icon: <PlayCircleOutlined />,
        has_access: true
    },
    {
        label: 'Test Suite', 
        href: 'test-suite',
        icon: <FolderOpenOutlined />,
        has_access: true
    },
    {
        label: 'Components',
        href: 'components',
        icon: <TagFilled/>,
        has_access: true
    },
    {
        label: 'Calendar', 
        href: 'calendar',
        icon: <CalendarOutlined />,
        has_access: true
    },
    {
        label: 'Dashboard',
        href: 'dashboards',
        icon: <BarChartOutlined />,
        has_access: true
    },
    {
        label: 'Manage',
        icon: <SettingOutlined />,
        has_access: true,
        children: [{
            label: 'Settings',
            href: 'manage/settings',
            has_access: userPerms.project?.manage_projects || false
        }, {
            label: 'Users',
            icon: <TeamOutlined />,
            href: 'manage/users',
            has_access: true
        }, {
            label: 'Usage',
            icon: <RadarChartOutlined />,
            href: 'manage/usage',
            has_access: true
        }, 
        {
            label: 'Arguments',
            icon: <ToolOutlined />,
            href: 'manage/args_scripts',
            has_access: userPerms.project?.manage_projects || false
        },
        // {
        //     label: 'Logs',
        //     icon: <HddOutlined />,
        //     href: 'manage/logs',
        //     has_access: true
        // }
    ]
    }]

    return menuItems
        .filter(item => item.has_access) // First filter top-level items
        .map(item => ({
            ...item,
            // Filter children where has_access is false if they exist
            children: item.children?.filter(child => child.has_access)
        }));
    }

export const teamMenuItems = (userPerms: UserPerms): sideMenuItems[] => {
    const menuItems: sideMenuItems[] = [
    {
        label: 'Wall',
        href: 'wall',
        icon: <HomeOutlined />,
        has_access: true
    },{
        label: 'Members',
        href: 'members',
        icon: <TeamOutlined />,
        has_access: true
    },
    {
        label: 'Manage',
        icon: <SettingOutlined />,
        has_access: userPerms.team?.manage_teams || false,
        children: [{
            label: 'Settings',
            href: 'settings',
            icon: <SettingOutlined />,
            has_access: userPerms.team?.manage_teams || false
        }]}]

        return menuItems
        .filter(item => item.has_access) // First filter top-level items
        .map(item => ({
            ...item,
            // Filter children where has_access is false if they exist
            children: item.children?.filter(child => child.has_access)
        }));
    }

export const userMenuItems = (userPerms: UserPerms): sideMenuItems[] => [{
    label: 'Profile',
    href: 'profile',
    icon: <UserOutlined />,
    has_access: true
},{
    label: 'My Projects',
    href: 'projects',
    icon: <ProjectOutlined />,
    has_access: true
},{
    label: 'My Teams',
    href: 'teams',
    icon: <TeamOutlined />,
    has_access: true
},{
    label: 'My Notifications',
    href: 'notifications',
    icon: <BellOutlined />,
    has_access: true
},{
    label: 'My Settings',
    href: 'settings',
    icon: <SettingOutlined />,
    has_access: true
},{
    label: 'My Organizations',
    href: 'organizations',
    icon: <BankOutlined />,
    has_access: true
},{
    label: 'My Tools',
    href: 'tools',
    icon: <ToolOutlined />,
    has_access: true
}
]

export const orgMenuItems = (userPerms: UserPerms): sideMenuItems[] => {
    const menuItems: sideMenuItems[] = [
    {
        label: 'Overview',
        href: 'overview',
        icon: <DashboardFilled />,
        has_access: true
    },{
        label: 'Projects',
        href: 'projects',
        icon: <ProjectOutlined />,
        has_access: true
    },{
        label: 'My Runs',
        href: 'test-reviews',
        icon: <CheckSquareFilled />,
        has_access: true
    },{
        label: 'Users',
        href: 'users',
        icon: <TeamOutlined />,
        has_access: true
    },{
        label: 'Components',
        href: 'components',
        icon: <TagFilled/>,
        has_access: true
    },{
        label: 'Teams',
        href: 'teams',
        icon: <TeamOutlined />,
        has_access: true
    },
    {
        label: 'Manage',
        icon: <SettingOutlined />,
        has_access: userPerms.organization?.manage_org_settings || userPerms.organization?.manage_org_permissions || userPerms.organization?.manage_org_jobs || userPerms.organization?.check_org_logs,
        children: [{
            label: 'Settings',
            href: 'manage/settings',
            icon: <SettingOutlined/>,
            has_access: userPerms.organization?.manage_org_settings || false
        },{
            label: 'Permissions',
            href: 'manage/permissions',
            icon: <KeyOutlined/>,
            has_access: userPerms.organization?.manage_org_permissions || false
        },{
            label: 'Jobs',
            href: 'manage/jobs',
            icon: <TagFilled/>,
            has_access: userPerms.organization?.manage_org_jobs || false
        },{
            label: 'Logs',
            icon: <HddOutlined />,
            href: 'manage/logs',
            has_access: userPerms.organization?.check_org_logs || false
        }]}]

    return menuItems
        .filter(item => item.has_access) // First filter top-level items
        .map(item => ({
            ...item,
            // Filter children where has_access is false if they exist
            children: item.children?.filter(child => child.has_access)
        }));

}
