import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import React from "react";
import ModalC from "../modal";
import { Form, Space, Table } from 'antd';
import { createWallPost } from "../../redux/actions/team_wall_actions";
import { useAppContext } from "../../AppContext";
import { AppDispatch } from "../../redux/store";
import { NewWallPost, Team, WallPosts } from "../../models/entities/team";
import { empty_post } from "../../services/EmptyEntities/EmptyPost";
import { PageResource } from "../../models/dtos/page_resource";
import { createEmptyPage } from "../../services/utils/PageResourceUtils";
import { getTeamStats } from "../../redux/actions/team_actions";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";


type PostProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectedPost: WallPosts;
  setSelectedPost: Dispatch<SetStateAction<WallPosts>>;
  checkbox?: any
}

interface DataType {
    key: React.Key;
    title: any;
    dataIndex: string;
    width: string;
}

export default function SharePostPopup(props: PostProps):JSX.Element {
  const dispatch: AppDispatch = useDispatch();
  const {currentOrg, currentTeam, loggedInUser} = useAppContext();

  const {visible, setVisible, selectedPost, setSelectedPost, checkbox} = props;
  const [defaultTeams, setDefaultTeams] = useState<PageResource<Team>>(createEmptyPage());
  const [checkedItems, setCheckedItems] = useState<React.Key[]>([])
//   const [wallPosts, setWallPosts] = useState<WallPosts[]>([])

  useEffect(() => {
      const fetchData = () => {
          dispatch(getTeamStats(currentOrg.id, loggedInUser.id, ''))
          .then(teams => {
              setDefaultTeams(teams)
        }).catch((err) => {safeHandleErrorResponse(err)})
      }
      fetchData()
  }, [dispatch])

  const onCreate = () => {
    checkedItems.forEach(team_id => {
        // dispatch(getTeamPosts({org_id: currentOrg.id, team_id: currentTeam.id, offset: 10})).then(posts => {
        //     setWallPosts(posts.content.reverse())})
        const new_post: NewWallPost = {
            org_id: currentOrg.id,
            team_id: team_id,
            post_text: selectedPost.post_text,
            post_image: selectedPost.post_image,
            user_id: loggedInUser.id
        }
        if (selectedPost.post_text.replace(/<\/?[^>]+(>|$)/g, '').length > 0) {
        dispatch(createWallPost(new_post, []))
            .catch((err) => {
            safeHandleErrorResponse(err)
        })
    }});
    setTimeout(() => {
        setVisible(false)
        setSelectedPost(empty_post);
        setCheckedItems([])
    }, 500);}


  const handleCancel = () => {
    setVisible(false)
    setSelectedPost(empty_post)
    setCheckedItems([])
};

  const constructTeamData = (): any[] => {
    return defaultTeams.content.map(team=>{
        return {
            key: team.id,
            title: team.name,
        }
    })
}

  const columns: DataType[] = [{
    title: "Select Desired Team(s)",
    dataIndex: "title",
    key: "title",
    width: "100%"}]

    const defaultCheckbox:{
        checkedPosts: React.Key[],
        setCheckedPosts: Dispatch<SetStateAction<React.Key[]>>
    } = checkbox? {
        checkedPosts: checkbox.checkedComponents,
        setCheckedPosts: checkbox.setCheckedPosts
    } : {
        checkedPosts: checkedItems,
        setCheckedPosts: setCheckedItems
    }
    
  const rowSelection = {
      selectedRowKeys: defaultCheckbox.checkedPosts,
      onChange: (selectedRowKeys: React.Key[]) => {
          defaultCheckbox.setCheckedPosts(selectedRowKeys)
      },
      getCheckboxProps: (record: DataType) => ({
        disabled: record.title === currentTeam.name, // Column configuration not to be checked
        name: record.title,
      })      
    };
    
  return (
    <ModalC open={visible} title='Share Post To Other Teams' onOk={onCreate} onCancel={handleCancel}>
      <Form.Item>   
        <Space.Compact>
        <Table 
            dataSource={constructTeamData()} 
            columns={columns} 
            rowSelection={{type: "checkbox", ...rowSelection}}
            style={{width: 450}}
            size='small'
            pagination={{pageSize: 10, hideOnSinglePage:true}}/>
        </Space.Compact>
      </Form.Item>
    </ModalC>
  );
}
