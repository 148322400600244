import {AppDispatch} from "../store";
import {fetchErrorAction, fetchStartAction, fetchSuccessAction} from "./creators/common_actions";
import { Component, NewComponent } from "../../models/entities/component";
import {PageResource} from "../../models/dtos/page_resource";
import {get, post, put, deletion} from '../../services/axiosWrapper';
const api_path = '/api/components/';

interface getComponentsParams {
    org_id: number,
    project_id?: number,
    inherit_org_components?: boolean,
    search?: string,
    select_owner?: string[]
}

export const createComponent = (data:NewComponent, components:Component[]) => (dispatch: AppDispatch): Promise<Component[]> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}create`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            value.data.new_component.cases = value.data.new_component.cases.length
            value.data = [...components, value.data.new_component]
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })

}

export const updateComponent = (data:Component, components:Component[]) => (dispatch: AppDispatch): Promise<Component[]> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateComponent`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            const component_index: number = components.findIndex(component => component.id === data.id)
            components[component_index] = data
            value.data = components
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })

}

export const deleteComponent = (component_id:number) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    const request: any = deletion(`${api_path}deleteComponent?component_id=${component_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteComponents = (params: {component_ids: React.Key[]}) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    const request: any = deletion(`${api_path}deleteComponents`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

// export const deleteComponents = (data: DeleteComponents) => (dispatch: AppDispatch): Promise<any> => {
//     dispatch(fetchStartAction());

//     // Create a request config object with data as a query parameter
//     const requestConfig: AxiosRequestConfig = {
//         method: 'delete',
//         url: `${api_path}deleteComponents`,
//         data: data, // Pass your data here as the request body
//     };

//     return axios(requestConfig)
//         .then((response) => {
//             dispatch(fetchSuccessAction());
//             return response.data; // Return the response data
//         })
//         .catch((error) => {
//             dispatch(fetchErrorAction(error));
//             return Promise.reject(error);
//         });
// };

export const getComponents = (params: getComponentsParams) => (dispatch: AppDispatch): Promise<PageResource<Component>> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getComponents?`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}