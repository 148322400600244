import React, {useEffect, useState} from 'react';
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import {
    getAddProjectSettingsActions, getProjectRunArgs
} from "../../redux/actions/project_actions";
import {useAppContext} from "../../AppContext";
import {ArgumentScriptSetting} from "../../models/entities/settings";
import {Col, Steps, FloatButton, Tabs, notification} from "antd";
import RowC from "../../components/row";
import RunConfigSettings from "./run_config_settings";
import RunConfigArgs from "./run_config_args";
import {DataNode} from "antd/lib/tree";
import {getSections} from "../../redux/actions/section_actions";
import {
    FolderOpenOutlined,
    PlayCircleOutlined,
    TagFilled
} from "@ant-design/icons";
import TestSuite from "../test_suite/test_suite";
import RunComplete from "./run_complete";
import {colors} from '../../assets/data/colors'
import Dropdown from "antd/lib/dropdown";
import {Component} from "../../models/entities/component";
import { checked_section, SuiteCases} from "../../models/entities/suite_section_props";
import {getComponents} from "../../redux/actions/component_actions";
import {PageResource} from "../../models/dtos/page_resource";
import {createEmptyPage} from "../../services/utils/PageResourceUtils";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import Components from "../components/components";
import {createNewTestRun} from "../../redux/actions/run_actions";
import dayjs, {Dayjs} from "dayjs";
import RunScripts from "./run_scripts";
import { RunSlacks, RunTeams } from '../../models/entities/run_script_notifications';
import TimeHandler from "../../assets/helpers/timeHandler";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {emptyPermissions} from "../../services/EmptyEntities/EmptyPermissions";
import {canStartRun} from "../../models/entities/permissions";
import {ModalLoadTemplates} from "../../components/modals/modalTemplates";
import {
    recurringOptions,
    RunConfiguration,
    runOptions,
    sequenceOptions
} from "../../models/entities/template";

export function Run(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const {currentProject, currentOrg, userPerms} = useAppContext()

    const [runTitle, setRunTitle] = useState<string>('New Run');  // Set this per project configuration
    const [githubBranch, setGithubBranch] = useState<string>('');  // Set this per project configuration

    // navigation
    const [step, setStep] = useState<number>(0);
    const [caseTab, setCaseTab] = useState<string>("1");

    // Step1 state general settings
    const [runOption, setRunOption] = useState<runOptions>('Manually');
    const [runDate, setRunDate] = useState<Dayjs | null>(dayjs());
    const [maxParallel, setMaxParallel]  = useState<number>(1);
    const [useMaxParallel, setUseMaxParallel]  = useState<boolean>(maxParallel === -1);
    const [reruns, setReruns] = useState<number>(0);

    // Step1 state recurring Browser
    const [selectedBrowsers, setSelectedBrowsers]  = useState<string[]>(['Chrome']);
    const [sequence, setSequence]  = useState<sequenceOptions>('Continuously');
    const [runInterval, setRunInterval]  = useState<number>(0);

    // Step1 state recurring settings
    const [isRecurring, setIsRecurring] = useState<boolean>(false);
    const [recurringOption, setRecurringOption] = useState<recurringOptions>('Week');
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
    const [endRecurring, setEndRecurring] = useState<boolean>(false);

    // step2 args
    const [args, setArgs] = useState<ArgumentScriptSetting[]>([])

    // step3 Notifications & Scripts
    const [systemNotifications, setSystemNotifications] = useState<{id: number, status: boolean; title: string;}[]>([]);
    const [scripts, setScripts] = useState<ArgumentScriptSetting[]>([]);
    const [slackNotifications, setSlackNotifications] = useState<RunSlacks[]>([]);
    const [teamNotifications, setTeamNotifications] = useState<RunTeams[]>([]);

    // Select Cases - By Case/folder
    const [treeData, setTreeData] = useState<DataNode[]>([])
    const [checkedSections, setCheckedSections] = useState<checked_section>({checked: [], halfChecked: []}) // not sure about this default state
    const [checkedCases, setCheckedCases] = useState<SuiteCases>({}) // not sure about this default state

    // Select Cases - By Component
    const [componentPage, setComponentPage] = useState<PageResource<Component>>(createEmptyPage())
    const [checkedComponents, setCheckedComponents] = useState<React.Key[]>([]) // not sure about this default state

    const checkedCasesIds = [].concat(...Object.values(checkedCases))

    // permissions
    const [permissionsToStartRun, setPermissionsToStartRun] = useState<canStartRun>(emptyPermissions)
    const [forceDisableButton, setForceDisableButton] = useState<boolean>(false)

    // template modal
    const [showModalTemplate, setSowModalTemplate] = useState<boolean>(false)

    const handleCreateRun = () =>{
        let data:RunConfiguration = {
            projectId: currentProject.id,
            projectType: 1,
            options: {
                settings: {
                    title: runTitle,
                    githubBranch,
                    browsers: selectedBrowsers,
                    total_runs: reruns + 1,
                    maxParallel,
                    runSchedule: {
                        option: runOption,
                        start: runOption === "Manually"? null : TimeHandler.getDatejsToSend(runDate),
                        recurring: isRecurring? {
                            every: recurringOption,
                            end: endRecurring? TimeHandler.getDatejsToSend(endDate): null
                        }: null
                    }
                },
                args,
                notifications: {
                    system: systemNotifications,
                    slack: slackNotifications,
                    scripts,
                    team: teamNotifications},
                selection:{
                    sections: checkedSections.checked,
                    components: checkedComponents,
                    cases: checkedCasesIds
                }
            }

        }
        let data2:any = {
            sequence: sequence === 'Continuously' ? {sequence: sequence} : {sequence: sequence, runInterval: runInterval},
        }

        setForceDisableButton(true)
        dispatch(createNewTestRun(data))
            .then((response:any)=>{
                console.log(response)
                if(response.success === false){
                    notification.error({
                        message: 'Empty Combination',
                        description: response.err,
                    });
                }else{
                    notification.success({
                        message: 'New Run Created',
                        description: 'Your cases has been reset',
                    });
                    setCheckedSections({checked:[], halfChecked:[]})
                    setCheckedCases({})
                    setCheckedComponents([])
                }
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })

        setTimeout(() => {
            setForceDisableButton(false)
        }, 2000);
    }

    const items: ItemType[] = userPerms.project.create_templates ?
    [
        { key: 1, label: 'Create', onClick: handleCreateRun},
        { key: 2, label: 'Save Template'},
        { key: 3, label: 'Save Template & Create'},
        { key: 4, label: 'Preview', onClick:()=>{setStep(4)}}
    ] :
    [
        { key: 1, label: 'Create', onClick: handleCreateRun},
        { key: 2, label: 'Preview', onClick:()=>{setStep(4)}}
    ]

    useEffect(() =>{
        // get  defaults from appContext
        dispatch(getAddProjectSettingsActions({project_id: currentProject.id}))
            .then((settings)=> {
                setGithubBranch(settings.branch)
                setReruns(settings.runs_number)
                setMaxParallel(settings.parallels)
                setUseMaxParallel(settings.parallels === -1)
            })
            .catch((err) => {safeHandleErrorResponse(err)})



        dispatch(getSections(currentOrg.id, currentProject.id))
            .then(foundPage => setTreeData([{title: currentProject.name,
                key: -currentProject.id,
                children: foundPage,
                selectable:true}])).catch((err) => {
                    safeHandleErrorResponse(err)
                })
            .catch((err) => {safeHandleErrorResponse(err)})


        const params = {
            org_id: currentOrg.id,
            project_id: currentProject.id
        }
            
        dispatch(getComponents(params))
            .then(foundPage => {
                setComponentPage(foundPage)
            })
            .catch((err) => {safeHandleErrorResponse(err)})

        dispatch(getProjectRunArgs({project_id: currentProject.id, org_id:currentOrg.id}))
            .then(args=>{setArgs(args.content)})
            .catch((err) => {safeHandleErrorResponse(err)})


    }, [dispatch])


    const handleSetGithubBranch = (e:any)=>{
        setGithubBranch(e.target.value)
    }

    const handleSetMaxParallel = (e: number|null) => {
        if(e){ setMaxParallel(e) }
    }

    const handleBrowserSelect = (e:string[]) => {
        if(e){ setSelectedBrowsers(e) }
    }

    const handleChangeReruns = (e:number|null) => {
        setReruns(e?e:0)
    }

    const select_tab_items = [{
        label: <span><FolderOpenOutlined /> Sections & Cases </span>,
        key: "1",
        children:
            <TestSuite
                title={'Select Cases'}
                sectionProps={{
                    sections:{
                        treeData,
                        setTreeData,
                        checkbox:{
                            checkedSections,
                            setCheckedSections
                        }
                    },
                }}
                casesProps={{
                    casesOptions:{
                        checkedCases,
                        setCheckedCases
                    }
                }}
                options={{useModal: false}}
            />

    },{
        label: <span><TagFilled/> Components </span>,
        key: "2",
        children: <Components
            componentPage={componentPage}
            checkbox={{checkedComponents,setCheckedComponents}}
        />
    }]

    const get_step_progress = (rules:boolean[]) => {
        const trueCount = rules.filter((rule) => rule).length
        return (trueCount/rules.length)*100
    }

    const all_rules: boolean[] = [
        runTitle !== "",
        githubBranch !== "",
        selectedBrowsers.length > 0,
        checkedSections.checked?.length > 0 || checkedCasesIds.length > 0 || checkedComponents.length > 0,
        runOption === "Scheduled" ? runDate !== null : true,  // only check runDate if runOption is "Scheduled"
        runOption === "Scheduled" ? endRecurring ? endDate !== null : true: true
    ];


    const all_steps:any = {
        0: {
            description: 'Settings',
            percent: get_step_progress([
                all_rules[0],
                all_rules[1],
                all_rules[2],
                all_rules[3]
            ]),
            childes: <RunConfigSettings settings={{
                run: {
                    runOption, setRunOption,
                    runDate,
                    setRunDate,
                    endDate,
                    setEndDate,
                    isRecurring,
                    setIsRecurring,
                    endRecurring,
                    setEndRecurring,
                    recurringOption,
                    setRecurringOption
                },
                runTitle, setRunTitle,
                githubBranch, handleSetGithubBranch,
                reruns, handleChangeReruns,
                useMaxParallel, setUseMaxParallel,
                maxParallel, handleSetMaxParallel,
                browser:{
                    handleBrowserSelect,
                    selectedBrowsers,
                    sequence,
                    setSequence,
                    runInterval,
                    setRunInterval
                }
            }}/>
        },
        1: {
            description: 'Arguments',
            percent: 100,
            childes: <RunConfigArgs args={{args, setArgs}} />
        },
        2: {
            description: 'Scripts & Notifications',
            percent: 100,
            childes: <RunScripts systemNotifications={systemNotifications} setSystemNotifications={setSystemNotifications} 
                                 scripts={scripts} setScripts={setScripts}
                                 slackNotifications={slackNotifications} setSlackNotifications={setSlackNotifications}
                                 teamNotifications={teamNotifications} setTeamNotifications={setTeamNotifications}/>
        },
        3: {
            description: 'Select Cases',
            percent: get_step_progress([all_rules[4]]),
            childes: <Tabs activeKey={caseTab}
                           onChange={(e)=>setCaseTab(e)}
                           items={select_tab_items}
            />
        },
        4: {
            description: 'Preview',
            percent: get_step_progress(all_rules),
            childes: <RunComplete
                settings={{
                    run: {
                        runDate,
                        endDate,
                        isRecurring,
                        runOption,
                        recurringOption,
                        title: runTitle
                    },
                    githubBranch,
                    reruns,
                    maxParallel,
                    handleSetMaxParallel,
                }}
                // args={}
                // scripts={}
                selected={
                    {
                        sections:checkedSections.checked,
                        cases:checkedCasesIds,
                        components:checkedComponents
                    }
                }
            />
        }
    }

    const get_step_items = (steps:any):any[] => {
        return  Object.keys(steps).map((step_item:any, key:any) => {
            return {
                title: `Step ${key+1}`,
                description: steps[step_item].description,
                disabled: steps[step_item].disabled
            }
        })
    }



    return (
        <Page title="Configure New Run">
            <PageHeader
                title={`Configure New Run: ${all_steps[step].description}`}
                headerButton={
                    <>
                        <Dropdown.Button
                            disabled={!all_rules.every((item:boolean)=>item) || forceDisableButton}
                            size={'small'}
                            style={{width: 100, display: 'inline', color:colors.green.forest}}
                            menu={{ items }}
                            onClick={handleCreateRun}
                        >
                            <div style={{ color:colors.green.bamboo, display: 'inline'}}><PlayCircleOutlined/> Create</div>
                        </Dropdown.Button>
                        {/*<InfoCircleOutlined />*/}
                    </>

                }
            />
            <PageContent>
                <RowC style={{flexFlow:"nowrap"}}>
                    <Col flex='160px'>
                        <Steps
                            current={step}
                            onChange={setStep}
                            // status="error"
                            percent={all_steps[step].percent}
                            direction="vertical"
                            items={get_step_items(all_steps)}
                        />
                    </Col>
                    <Col flex='25px'/>
                    <Col flex='auto'>
                        {all_steps[step].childes}
                    </Col>
                </RowC>
                {
                    step !==4 ?
                        <FloatButton
                            style={{marginRight:15}}
                            type="primary"
                            onClick={()=>{setSowModalTemplate(true)}}
                            tooltip={<div>Templates</div>} />
                        :null

                }
            </PageContent>

            <ModalLoadTemplates
                setSowModalTemplate={setSowModalTemplate}
                showModalTemplate={showModalTemplate}
            />
        </Page>
    )
}

export default Run;