import React, {useEffect, useState} from 'react';
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";


function RunReviewLogs(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const [logs, setLogs]  = useState();

    useEffect(()=>{

    }, [dispatch])


    return <>
        run - System logs
    </>
}

export default RunReviewLogs;