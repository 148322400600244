import React, { useEffect, useState } from 'react';
import {Dropdown, Input, Space, Table,} from "antd";
import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import ButtonC from '../../components/button';
import CancelAction from '../../components/notification/cancel_req';
import { SlackIntSetting } from '../../models/entities/settings';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { useAppContext } from '../../AppContext';
import SlackChannels from '../modals/slack_modals';
import { empty_slack } from '../../services/EmptyEntities/EmptySlack';
import { deleteSlackIntegration, getProjectSlackIntegrations } from '../../redux/actions/project_actions';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

const { Search } = Input;


function Slacks(): JSX.Element{
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg, currentProject} = useAppContext();
    const org_id = currentOrg.id
    const project_id = currentProject.id

    const params = {org_id, project_id}

    const [visible, setVisible] = useState<boolean>(false);
    const [checkedSlacks, setCheckedSlacks] = useState<any[]>([])
    const [savedSlacks, setSavedSlacks] = useState<SlackIntSetting[]>([]);
    const [selectedSlack, setSelectedSlack] = useState<SlackIntSetting | undefined>(empty_slack);
    const [search, setSearch] = useState<string>('')

    const selected_slacks: number = checkedSlacks ? checkedSlacks.length : 0

    useEffect(() => {
        dispatch(getProjectSlackIntegrations(params))
            .then(slacks => {
                setSavedSlacks(slacks.content);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }, [dispatch])
    
    const handleDeleteDefaultSlacks = () => {
    
        const params = {
            project_id: currentProject.id,
            org_id: org_id,
            ids: checkedSlacks}
    
            dispatch(deleteSlackIntegration(params)).then(() => {
                let updatedList = savedSlacks.filter((item => !checkedSlacks.includes(item.id)));
                setSavedSlacks(updatedList);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: "url",
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: "channel",
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: "active",
        },
        {
            title: 
                <>  
                <Dropdown
                    disabled={selected_slacks === 0}
                    menu={{
                        items: [{
                            key: `mass-delete`,
                            label: <CancelAction secs={2} action={handleDeleteDefaultSlacks}><DeleteOutlined /> Delete</CancelAction>,
                        }]
                    }}
                >
                    <a>
                        <Space>
                            Actions <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
                <div style={{fontSize: 11}}>selected {selected_slacks}</div></>,
            dataIndex: 'actions',
            key: 'actions'
        }
    ];

    const fetchData = async () => {
        dispatch(getProjectSlackIntegrations({org_id, project_id, search}))
            .then(slacks => {
                setSavedSlacks(slacks.content)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    return (
        <>
             <Search
                style={{marginBottom: 10, width: 250, float: 'left'}}
                placeholder="Search for slack"
                onSearch={fetchData}
                value={search}
                onChange={(value)=>setSearch(value.target.value)}
                />
            <Table
                style={{width: '100%'}}
                pagination={{pageSize: 10, hideOnSinglePage:true}}
                rowSelection={{
                    selectedRowKeys: checkedSlacks,
                    onChange: (keys) => {
                        setCheckedSlacks(keys)
                    }
                }}
                dataSource={savedSlacks && savedSlacks.map((slack) => ({
                    key: slack.id,
                    name: slack.title,
                    url: slack.url,
                    channel: slack.channel_id,
                    active: slack.status === 1 || slack.status === true ? 'Yes' : 'No',
                    actions: <><EditOutlined onClick={() => {setVisible(true); setSelectedSlack(slack)}}/> Edit </>
                }))}
                key='slacks'
                columns={columns}
            />
            <ButtonC type={"primary"} onClick={()=>setVisible(true)} text={'Add New'} style={{marginTop: 10}}/>
            <SlackChannels visible={visible} setVisible={setVisible} 
                           slacks={savedSlacks} setSlacks={setSavedSlacks}
                           selectedSlack={selectedSlack} setSelectedSlack={setSelectedSlack}/>
        </>
    )
}

export default Slacks;
