import {canStartRun} from "../../models/entities/permissions";

export const emptyPermissions:canStartRun = {
    programmingLanguage:{
        modules: true,
        language: true
    },
    org:{
        limitConcurrency: -1,
        limitParallel: -1
    },
    framework:true,
    repo:{
        token: true,
        url: true,
        name: true
    }
}
