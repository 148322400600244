import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Form, MenuProps} from 'antd';
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import ModalC from "../modal";
import ButtonC from "../button";
import {DataNode} from "antd/lib/tree";
import {useAppContext} from "../../AppContext";
import TestSuiteFolders from "../../views/test_suite/test_suite_folders";
import {SectionProps} from "../../models/entities/suite_section_props";
import {colors} from "../../assets/data/colors";
import {getSections} from "../../redux/actions/section_actions";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";


type ModalProps = {
    modalOptions:{
        title: string,
        visible: boolean,
        setVisible: Dispatch<SetStateAction<boolean>>,
        buttonText: string,
        handleOk: any,  // function
        errMsg: string
    }
    selectedSection?: DataNode,
    sections: SectionProps
}

export function SectionsModal({sections, selectedSection, modalOptions}: ModalProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const [form] = Form.useForm();

    const {currentOrg, currentProject} = useAppContext();

    const {title, visible, setVisible, buttonText, handleOk, errMsg} = modalOptions
    // const

    const [hasModalBeenOpened, setHasModalBeenOpened] = useState(false);

    // construct menu. Dont show Case on Project Root Folder
    let subMenuItems: MenuProps['items'] = [{label: 'Section', key: 'Section'}]
    if (selectedSection && selectedSection.key as number > 0){subMenuItems.push({label: 'Case', key: 'Case'})}
    subMenuItems.push({label: 'Upload File', key: 'Upload File'})

    useEffect(() => {
        if (visible && !hasModalBeenOpened) {
            setHasModalBeenOpened(true); // Mark that the modal has been opened
        }
    }, [visible, hasModalBeenOpened]);

    useEffect(() => {
        if (hasModalBeenOpened && sections.treeData.length === 0) {
            dispatch(getSections(currentOrg.id, currentProject.id))
                .then(foundPage => {
                    sections.setTreeData([{
                        title: currentProject.name,
                        key: -currentProject.id,
                        children: foundPage,
                        selectable: false
                    }])
                })
                .catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }
    }, [dispatch, hasModalBeenOpened])

    const handleCloseModal = () => {
        setVisible(false)
    }

    const handleDeleteSection = () => {
        // console.log('delete files or transfer to parent?')
    }

    const get_footer_buttons = () => {
        let footer_buttons = [
            <ButtonC key="submit_close" onClick={() => setVisible(false)} text="Close"/>,
            <ButtonC key="submit_create" type="primary" onClick={() => handleOk(form)}
                     text={buttonText}/>
        ]

        if (selectedSection && parseInt(selectedSection.key.toString()) > 0) {
            footer_buttons.push(
                <ButtonC key="delete_section"
                         danger
                         style={{float: 'left'}}
                         onClick={handleDeleteSection}
                         text="Delete"/>)
        }
        return footer_buttons
    }

    return (
        <ModalC
            open={visible}
            maskClosable={false}
            onCancel={handleCloseModal}
            title={title}
            footer={get_footer_buttons()}
        >
            <div style={{height:500, overflow:'auto'}}>
                {
                    errMsg? <div style={{color:colors.red.vermilion}}>{errMsg}</div>: null
                }

                <TestSuiteFolders
                    divider={{showDivider:false, isExpandedTree:true, setIsExpandedTree:()=>{}}}
                    sections={sections}
                    treeOptions={{multiple:false}}
                />

            </div>
        </ModalC>
    )
}