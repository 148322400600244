import React, {useState} from 'react';
import {message, Upload, UploadFile, UploadProps} from 'antd';
import {RcFile} from "antd/lib/upload";


type UploaderType = {
  action: string,
  listType: 'text' | 'picture' | 'picture-card',
  max_items: number,
  url?: string
}

const UploaderC = ({action, listType, url, max_items=1}:UploaderType): JSX.Element =>{
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: url? url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
  ]);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
      <Upload action= {action}
              listType= {listType}
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}>
        {fileList.length < max_items && '+ Upload'}
      </Upload>
  )
}

export default UploaderC;