import React, { ReactNode } from 'react';

interface CenteredWrapperProps {
    children: ReactNode; // ReactNode allows any valid React child (e.g., JSX, strings, numbers, etc.)
}

const CenteredWrapper: React.FC<CenteredWrapperProps> = ({ children }) => {
    return (
        <div style={{ display: 'grid', placeItems: 'center', margin: '20px 0' }}>
            {children}
        </div>
    );
};

export default CenteredWrapper;