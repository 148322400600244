import React, {createContext, useContext, useState} from 'react';
import LcsManager from './assets/local_storage/lcs'
// models
import {Tool, User, UserPerms} from './models/entities/user';
import {Organization} from './models/entities/organization';
import {Project, ProjectRunSettings} from './models/entities/project';
import {Team} from "./models/entities/team";
// empty entities
import {empty_user, empty_user_perms} from './services/EmptyEntities/EmptyUser';
import {empty_organization} from './services/EmptyEntities/EmptyOrganization';
import {empty_project} from './services/EmptyEntities/EmptyProject';
import {empty_team} from "./services/EmptyEntities/EmptyTeam";
import {empty_project_settings} from './services/EmptyEntities/EmptyProjectSettings';

const lcs_manager = new LcsManager()

// Default set to lcs
const set_project_to_lcs = (project: Project) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_project, project)
}

const set_org_to_lcs = (org: Organization) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_org, org)
}

const set_team_to_lcs = (team: Team) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_team, team)
}

const set_user_to_lcs = (user: User) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.user, user)
}

const set_user_perms_to_lcs = (perms: UserPerms) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.user_perms, perms)
}

const set_user_token_to_lcs = (token: string) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.user_token, token)
}

const set_project_settings_to_lcs = (settings: ProjectRunSettings) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_project_settings, settings)
}

const lcsUser = lcs_manager.get_item_from_lcs(lcs_manager.Keys.user);
const lcsUserToken = lcs_manager.get_item_from_lcs(lcs_manager.Keys.user_token);
const lcsUserPerms = lcs_manager.get_item_from_lcs(lcs_manager.Keys.user_perms);
const lcsOrganization = lcs_manager.get_item_from_lcs(lcs_manager.Keys.current_org);
const lcsProject = lcs_manager.get_item_from_lcs(lcs_manager.Keys.current_project);
const lcsTeam = lcs_manager.get_item_from_lcs(lcs_manager.Keys.current_team);
const lcsProjectSettings = lcs_manager.get_item_from_lcs(lcs_manager.Keys.current_project_settings);

interface AppContextProps {
    loggedInUser: User,
    setAppContextUser: (user: User) => void,
    userToken: string,
    setUserToken: (token: string) => void,
    userPerms: UserPerms,
    setUserPerms: (perms: UserPerms) => void,
    currentOrg: Organization,
    setAppContextOrg: (org: Organization) => void,
    currentProject: Project,
    setAppContextProject: (project: Project) => void,
    currentTeam: Team,
    setAppContextTeam: (team: Team) => void,
    currentProjectSettings: ProjectRunSettings,
    setAppContextProjectSettings: (project_settings: ProjectRunSettings) => void,
    resetApp: () => void,
    tools: Tool[],
    setTools: (tools: Tool[]) => void
}

const defaultValue: AppContextProps = {
    loggedInUser: lcsUser ? lcsUser : empty_user,
    setAppContextUser: set_user_to_lcs,
    userToken: lcsUserToken ? lcsUserToken : "",
    setUserToken: set_user_token_to_lcs,
    userPerms: lcsUserPerms ? lcsUserPerms : empty_user_perms,
    setUserPerms: set_user_perms_to_lcs,
    currentOrg: lcsOrganization ? lcsOrganization : empty_organization,
    setAppContextOrg: set_org_to_lcs,
    currentProject: lcsProject ? lcsProject : empty_project,
    setAppContextProject: set_project_to_lcs,
    currentTeam: lcsTeam ? lcsTeam : empty_team,
    setAppContextTeam: set_team_to_lcs,
    currentProjectSettings: lcsProjectSettings ? lcsProjectSettings : empty_project_settings,
    setAppContextProjectSettings: set_project_settings_to_lcs,
    resetApp: () => {},
    tools: [],
    setTools: ([]) => {}
};

type AppProviderProps = {
    children: React.ReactNode;
};

const AppContext = createContext<AppContextProps>(defaultValue);

export const AppProvider: React.FC<AppProviderProps> = ({children}) => {
    const [loggedInUser, setAppContextUser] = useState<User>(defaultValue.loggedInUser)
    const [userToken, setUserToken] = useState<string>(defaultValue.userToken)
    const [userPerms, setUserPerms] = useState<UserPerms>(defaultValue.userPerms)
    const [currentOrg, setAppContextOrg] = useState<Organization>(defaultValue.currentOrg)
    const [currentProject, setAppContextProject] = useState<Project>(defaultValue.currentProject)
    const [currentProjectSettings, setAppContextProjectSettings] = useState<ProjectRunSettings>(defaultValue.currentProjectSettings)
    const [currentTeam, setAppContextTeam] = useState<Team>(defaultValue.currentTeam)
    const [tools, setAppContextTools] = useState<Tool[]>([])

    const set_loggedin_user = (user: User) => {
        setAppContextUser(user)
        lcs_manager.set_item_to_lcs(lcs_manager.Keys.user, user)
    }

    const set_user_token = (token: string) => {
        setUserToken(token)
        lcs_manager.set_item_to_lcs(lcs_manager.Keys.user_token, token)
    }


    const set_user_perms = (perms: UserPerms) => {
        setUserPerms(perms)
        lcs_manager.set_item_to_lcs(lcs_manager.Keys.user_perms, perms)
    }

    const set_current_org = (org: Organization) => {
        if(org !== currentOrg){
            setAppContextOrg(org)
            lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_org, org)
        }
    }

    const set_current_project = (project: Project) => {
        if(project !== currentProject){
            setAppContextProject(project)
            lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_project, project)
        }
    }

    const set_current_team = (team: Team) => {
        if(team !== currentTeam){
            setAppContextTeam(team)
            lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_team, team)
        }
    }

    const set_current_project_settings = (project_settings: ProjectRunSettings) => {
        setAppContextProjectSettings(project_settings)
        lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_project_settings, project_settings)
    }

    const resetApp = () => {
        setAppContextUser(empty_user);
        setUserToken("");
        setUserPerms(empty_user_perms)
        setAppContextOrg(empty_organization);
        setAppContextProject(empty_project);
        setAppContextTeam(empty_team);
        setAppContextProjectSettings(empty_project_settings);
        lcs_manager.reset_local_storage([lcs_manager.Keys.is_sidemenu_collapsed]);
    };

    const value: AppContextProps = {
        loggedInUser,
        setAppContextUser: set_loggedin_user,
        userToken,
        setUserToken: set_user_token,
        userPerms,
        setUserPerms: set_user_perms,
        currentOrg,
        setAppContextOrg: set_current_org,
        currentProject,
        setAppContextProject: set_current_project,
        currentTeam,
        setAppContextTeam: set_current_team,
        currentProjectSettings,
        setAppContextProjectSettings: set_current_project_settings,
        resetApp,
        tools: tools,
        setTools: setAppContextTools
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};


export const useAppContext = (): AppContextProps => {
    return useContext(AppContext);
};
