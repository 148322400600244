
import React, { useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
// models
import { OrgUsersInfo } from '../../models/entities/user';
import { PageResource } from "../../models/dtos/page_resource";
import { createEmptyPage } from "../../services/utils/PageResourceUtils";
import { empty_org_user_info } from "../../services/EmptyEntities/EmptyUser";
// components
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
// antd
import { UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { Button, Input, Tag } from 'antd';
// actions
import {deleteOrgUser, getOrgUserInfo, updateOrgRole} from "../../redux/actions/user_actions";
import { Link } from 'react-router-dom';
import {useAppContext} from "../../AppContext";
import { UserModal } from '../../components/modals/user_modal';
import TableUsers from "../../components/tables/table_users";
import {allowedExtraUserColumns} from "../../models/entities/helpers/table";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

const { Search } = Input;

function OrgUsers(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const {currentOrg, currentProject, userPerms} = useAppContext();

    const org_id = currentOrg.id

    const [userPage, setUsersPage] = useState<PageResource<OrgUsersInfo>>(createEmptyPage());
    const [selectedUser, setSelectedUser] = useState<OrgUsersInfo>(empty_org_user_info);
    const [visible, setVisible] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('')

    useEffect(() => {
        const fetchData = () => {
            dispatch(getOrgUserInfo(org_id, search)).then(users => {
                setUsersPage(users)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch, currentOrg])

    let users = userPage.content
    const extra_columns: allowedExtraUserColumns[] = userPerms.organization.manage_org_users ? ['jobs', 'teams', 'actions']: ['jobs', 'teams']

    const deleteUsers = (user_ids: number[]) => {
        dispatch(deleteOrgUser(org_id, user_ids))
            .then(() => {
                // console.log('users', users)
                // console.log('user_ids', user_ids)
                // Filter out the checkedUsers from the users array
                let updatedContent = users.filter((user: OrgUsersInfo) => !user_ids.includes(user.user_info.id));
                // console.log('updatedContent', updatedContent)

                // Update the content property in updatedList
                let updatedList: any = {
                    ...userPage,  // Assuming props.userPage contains other properties you want to keep unchanged
                    content: updatedContent
                };

                setUsersPage(updatedList);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            });
    }

    const fetchData = async () => {
        dispatch(getOrgUserInfo(org_id, search)).then(foundPage => {
            setUsersPage(foundPage);
    }).catch((err) => {
        safeHandleErrorResponse(err)
    })}

    const updateUserRole = async (user: OrgUsersInfo) => {
        dispatch(updateOrgRole({org_id: org_id, id: user.user_info.id})).then((found) => {
            setUsersPage(prevUsers => ({
                ...prevUsers,
                content: prevUsers.content.map(u => 
                    u.user_info.id === user.user_info.id ? { ...u, role: found.new_role, role_label: found.new_label, permission: found.permissions } : u
                )
            }));
        }).catch((err) => {
            safeHandleErrorResponse(err)
        });
    };

    const setData = () => {
        let data: any = [];
        if(users){
            users.forEach((user)=> {
                data.push({
                    key: user.user_info.id,
                    name: <Link to={`/${currentOrg.domain}/users/${user.user_info.id}/view`} title="Go To Members Profile" style={{ color: "inherit"}}>{`${user.user_info.lastname} ${user.user_info.name}`}</Link>,
                    email: user.user_info.email,
                    teams: user.user_teams_info ? 
                            [user.user_teams_info.map( (team) =>{
                                return <Tag key={`UT-${user.user_info.id}-${team.id.toString()}`}> {team.name} </Tag>
                            })]: [],
                    jobs: user.user_jobs_info ?
                            [user.user_jobs_info.map( (job) => {
                                return <Tag color={job.color} key={`UJ-${job.id.toString()}`}> {job.name} </Tag>
                            })] : [], //Tags view table, getAllJobsForAUser()
                    role: user.role_label, 
                    actions: 
                    userPerms.organization.manage_org_users ?
                      (
                        <Button type='text' disabled={user.role === 1} onClick={() => updateUserRole(user)}>
                          {user.role === 1 || user.role === 2 ? <div><UserAddOutlined /> Remove as Admin </div> : <div><UserDeleteOutlined /> Add as Admin </div>}
                        </Button>
                          
                      )
                      : null
                });
            });
        }
        return data;
    } 

    const buttonHeader = userPerms.organization.manage_org_users
        ? { type: "create" as const, onClickFunction: () => setVisible(true) }
        : null;

    return (
        <>
            <Page title={`${currentOrg.name} Users`}>
                <PageHeader title={`${currentOrg.name} Users`}
                            buttonHeader={buttonHeader}/>
                <PageContent>
                <Search
                    style={{marginBottom: 10, width: 250, float: 'left'}}
                    placeholder="Search for user"
                    onSearch={fetchData}
                    value={search}
                    onChange={(value)=>setSearch(value.target.value)}
                />
                    <TableUsers
                        data = {setData()}
                        deleteUserAction={deleteUsers}
                        permissions={userPerms.organization.manage_org_users}
                        limit={userPage.limit}
                        // fetchFilteredUsers={(value_type: string, value:React.Key | boolean) => true}
                        extra_columns={extra_columns}
                    />
                    <UserModal  setVisible={setVisible}
                                visible={visible}
                                user={selectedUser}
                                userPage={userPage}
                                setUserPage={setUsersPage}
                                setUser={setSelectedUser}
                    />
                </PageContent>
            </Page>
        </>
    )
}

export default OrgUsers;
