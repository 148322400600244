import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type ChildComponentProps = {
    label: React.ReactNode,
    onChange?:  (e: CheckboxChangeEvent) => void ,
    onClick?: (e: React.MouseEvent) => void,
    style?: React.CSSProperties,
    defaultChecked?: boolean,
    checked?: boolean
  };


function CheckboxC(props: ChildComponentProps): JSX.Element {
    const {label, style, onChange, defaultChecked, checked, onClick} = props

    return <Checkbox
        style={style}
        value={defaultChecked}
        checked={checked}
        onClick={onClick}
        onChange={onChange}>
        <div onClick={onClick}>{label}</div>
    </Checkbox>

}
export default CheckboxC;