import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import {getComponents, deleteComponents} from "../../redux/actions/component_actions";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {useAppContext} from "../../AppContext";
import {PageResource} from "../../models/dtos/page_resource";
import {Component, ComponentProps} from "../../models/entities/component";
import {createEmptyPage} from "../../services/utils/PageResourceUtils";
import { DeleteOutlined, DownOutlined, MoreOutlined } from "@ant-design/icons";
import {ComponentModal} from "../../components/modals/component_modal";
import {empty_component} from "../../services/EmptyEntities/EmptyComponent";
import type { MenuProps } from 'antd';
import { Dropdown, Input, Select, Space } from 'antd';
import { colors } from '../../assets/data/colors';
import { Table } from 'antd/lib';
import CancelAction from '../../components/notification/cancel_req';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

const {Option} = Select;
const { Search } = Input;

interface DataType {
    key: React.Key;
    title: any;
    dataIndex: string;
    width: string;
}

function Components({componentPage, setComponentPage, checkbox, hideGlobal}:ComponentProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg, currentProject} = useAppContext();

    const {project_key} = useParams()

    const [initComponentPage, setInitComponentPage] = useState<PageResource<Component>>(createEmptyPage())
    const [visible, setVisible] = useState<boolean>(false);
    const [copy, setCopy] = useState<boolean>(false);
    const [selectedComponent, setSelectedComponent] = useState<Component>(empty_component);
    const [checkedItems, setCheckedItems] = useState<React.Key[]>([])
    const [search, setSearch] = useState<string>('')
    const [owners, setOwners] = useState<{id: number, fullname: string}[]>()
    const [selectedOwners, setSelectedOwners] = useState<string>()

    const defaultComponentPage = componentPage? componentPage: initComponentPage
    const defaultSetComponentPage = setComponentPage? setComponentPage: setInitComponentPage
    const defaultCheckbox:{
        checkedComponents: React.Key[],
        setCheckedComponents: Dispatch<SetStateAction<React.Key[]>>
    } = checkbox? {
        checkedComponents: checkbox.checkedComponents,
        setCheckedComponents: checkbox.setCheckedComponents
    } : {
        checkedComponents: checkedItems,
        setCheckedComponents: setCheckedItems
    }
    const params = {
        org_id: currentOrg.id,
        project_id: currentProject.id
    }

    const handleStatusChange = (event: any) => {
        setSelectedOwners(event);
        fetchData(event);
    };

    useEffect(() => {
        const fetchData = async () => {
            if(componentPage){
                defaultSetComponentPage(componentPage)

                let data: {id: number, fullname: string}[] = [];
                let seenIds = new Set();

                componentPage.content.forEach(user => {
                    const ownerId = user.owner ? user.owner.id : -1;
                    const ownerFullname = user.owner ? user.owner.fullname : '';
                    
                    if (!seenIds.has(ownerId)) {
                        seenIds.add(ownerId);
                        data.push({id: ownerId, fullname: ownerFullname});
                    }
                });

                setOwners(data);

            }else{
                dispatch(getComponents(params)).then(foundPage => {
                    defaultSetComponentPage(foundPage)

                    let data: {id: number, fullname: string}[] = [];
                    let seenIds = new Set();

                    foundPage.content.forEach(user => {
                        const ownerId = user.owner ? user.owner.id : -1;
                        const ownerFullname = user.owner ? user.owner.fullname : '';
                        
                        if (!seenIds.has(ownerId)) {
                            seenIds.add(ownerId);
                            data.push({id: ownerId, fullname: ownerFullname});
                        }
                    });

                    setOwners(data);
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
            }

        }
        fetchData()
    }, [dispatch])

    const get_user_profile_link = (user_id:number):string =>{
        let url = `/${currentOrg.domain}`
        if(project_key){
            url += `/projects/${currentProject.p_key}/users/${user_id}/view`
        }
        else{
            url += `/user/${user_id}/profile`
        }
        return url
    }

    const handleDeleteSelectedComponent = () => {
            dispatch(deleteComponents({component_ids: defaultCheckbox.checkedComponents}))
            .then(()=>{
                let new_page = {...defaultComponentPage}
                new_page.content = new_page.content.filter(item => !defaultCheckbox.checkedComponents.includes(item.id))

                defaultSetComponentPage(new_page);
                defaultCheckbox.setCheckedComponents([])
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    const constructComponentData = (): any[] => {
        return defaultComponentPage.content.map(component=>{
            const items: MenuProps['items'] = [
                {
                key: `edit-${component.id}`,
                label: 'Edit'
                },{
                key: `copy-${component.id}`,
                label: 'Copy'
            }]

            const onMenuClick: MenuProps['onClick'] = (e) => {
                if(e.key === `edit-${component.id}`){
                    setSelectedComponent(component)
                    setVisible(true)
                }
                if(e.key === `copy-${component.id}`){
                    setSelectedComponent(component)
                    setVisible(true)
                    setCopy(true)
                }}
            return {
                key: component.id,
                title: component.title,
                description: component.description,
                cases: component.cases,
                owner: component.owner?
                    <Link to={get_user_profile_link(component.owner.id)}>{component.owner.fullname}</Link>
                    : null,
                actions:
                    <Dropdown menu={{ items, onClick: onMenuClick }} trigger={['click']}>
                        <Space>
                            <MoreOutlined style={{color:colors.green.forest}}/>
                        </Space>
                    </Dropdown>
            }
        })
    }

    const columns: DataType[] = [{
        title: "Title",
        dataIndex: "title",
        key: "title",
        width: "30%"
    },{
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "30%"
    },{
        title: "Connected Cases",
        dataIndex: "cases",
        key: "cases",
        width: "10%"
    },{
        title: "Owner",
        dataIndex: "owner",
        key: "owner",
        width: "25%"
    },{
        title: 
                <>  
                <Dropdown
                    disabled={defaultCheckbox.checkedComponents.length === 0}
                    menu={{
                        items: [{
                            key: `mass-delete`,
                            label: <CancelAction secs={2} action={handleDeleteSelectedComponent}><DeleteOutlined /> Delete</CancelAction>,
                        }]
                    }}
                >
                    <a>
                        <Space>
                            Actions <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
                <div style={{fontSize: 11}}>selected {defaultCheckbox.checkedComponents.length}</div></>,
            dataIndex: "actions",
            key: "actions",
            width: "5%"
    }]

    const rowSelection = {
        selectedRowKeys: defaultCheckbox.checkedComponents,
        onChange: (selectedRowKeys: React.Key[]) => {
            defaultCheckbox.setCheckedComponents(selectedRowKeys)
        }
      };

    const fetchData = async (owners: any = selectedOwners) => {
        const searching: any = owners 
            ? { org_id: currentOrg.id, project_id: currentProject.id, search: search, select_owner: owners }
            : { org_id: currentOrg.id, project_id: currentProject.id, search: search };
            
        const components = await dispatch(getComponents(searching));
        defaultSetComponentPage(components);
    };

    return (
        <Page title="Components">
            <PageHeader title="Components" buttonHeader={{type: "create", onClickFunction:()=>setVisible(true)}}/>
            <PageContent>
            <Select
               mode="multiple"
               style={{width: 250}}
               placeholder="Select Component Owners"
               value={selectedOwners}
               onChange={handleStatusChange}
               optionLabelProp="label"
               >
                {owners && owners.map(owner=>{
                                        return <Option key={`s-${owner.id}`} value={owner.fullname} label={owner.fullname}>
                                            {owner.fullname}
                                        </Option>
                                    })}
            </Select>
            <Search
                style={{marginBottom: 10, marginLeft: 10, width: 250}}
                placeholder="Search for component"
                onSearch={() => fetchData()}
                value={search}
                onChange={(value)=>setSearch(value.target.value)}
                />
                <Table
                    dataSource={constructComponentData()} 
                    columns={columns} 
                    rowSelection={{type: "checkbox", ...rowSelection}}
                    size='small'
                    pagination={{pageSize: 15, hideOnSinglePage:true}}/>
                <ComponentModal visible={visible}
                                setVisible={setVisible}
                                componentPage={defaultComponentPage}
                                setComponentPage={defaultSetComponentPage}
                                selectedComponent={selectedComponent}
                                setSelectedComponent={setSelectedComponent}
                                copy={copy} setCopy={setCopy} 
                                hideGlobal={hideGlobal}
                />
            </PageContent>
        </Page>
    )
}
  
export default Components;